// Mobile
.success-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color);
    color: var(--white);
    text-align: center;
}
.success-page .bodytext {
    color: var(--white);
    margin-left: auto;
    margin-right: auto;
    width: 83vw;
}

.success-page-super-header {
    margin-bottom: var(--mobile-success-page-super-header-margin);
    margin-left: auto;
    margin-right: auto;
    width: 83vw;
}

.success-page-image {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    margin-bottom: 16px;
}

// Tablet
@media (min-width: 768px) { 
    .success-page-super-header {
        margin-bottom: var(--tablet-success-page-super-header-margin);
        width: 74vw;
    }
    .success-page .bodytext {
        width: 74vw;
    }

}

// Desktop
@media (min-width: 1024px) { 
    .success-page-super-header {
        margin-bottom: var(--desktop-success-page-super-header-margin);
    }
    .success-page .bodytext {
        width: 36vw;
    }
}