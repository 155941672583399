:root {

    // =================================== Label ==================================

    // Fonts
    --checkbox-label-checkbox-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-checkbox-label-checkbox-font-size: var(--mobile-body-font-size);
    --mobile-checkbox-label-checkbox-line-height: var(--mobile-body-line-height);
    --mobile-checkbox-label-checkbox-letter-spacing: var(--mobile-body-letter-spacing);
    --mobile-checkbox-label-checkbox-font-weight: var(--mobile-body-font-weight);

    // Tablet/Desktop Fonts
    --tablet-checkbox-label-checkbox-font-size: var(--tablet-body-font-size);
    --tablet-checkbox-label-checkbox-line-height: var(--tablet-body-line-height);
    --tablet-checkbox-label-checkbox-letter-spacing: var(--tablet-body-letter-spacing);
    --tablet-checkbox-label-checkbox-font-weight: var(--tablet-body-font-weight);

    // Mobile Margins
    --mobile-checkbox-label-checkbox-margin-top: var(--mobile-green-spacing);
    --mobile-checkbox-label-checkbox-margin-bottom: var(--mobile-green-spacing);
    --mobile-checkbox-label-text-checkbox-margin-left: var(--mobile-pink-spacing);
    
    // Tablet Margins
    --tablet-checkbox-label-checkbox-margin-top: var(--tablet-green-spacing);
    --tablet-checkbox-label-checkbox-margin-bottom: var(--tablet-green-spacing);
    --tablet-checkbox-label-text-checkbox-margin-left: var(--tablet-pink-spacing);

    // Desktop Margins    
    --desktop-checkbox-label-checkbox-margin-top: var(--desktop-green-spacing);
    --desktop-checkbox-label-checkbox-margin-bottom: var(--desktop-green-spacing);
    --desktop-checkbox-label-text-checkbox-margin-left: var(--desktop-pink-spacing);

    // ================================= Checkmark ===============================

    // Colors
    --checkbox-checkmark-border-color: var(--demo-form-gray);
    --checkbox-checkmark-checked-background-color: var(--accent-color);
    --checkbox-checkmark-color: var(--checkmark-color);


    --checkbox-validation-message-text-color: var(--fail-state);

}