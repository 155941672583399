// Mobile
.card {
    border-radius: 16px;
    border: 2px solid var(--card-border-color);
    padding-left: var(--mobile-card-padding-left);
    padding-right: var(--mobile-card-padding-right);
    padding-top: var(--mobile-card-padding-top);
    padding-bottom: var(--mobile-card-padding-bottom);
    margin-bottom: var(--mobile-card-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .card {
        padding-left: var(--tablet-card-padding-left);
        padding-right: var(--tablet-card-padding-right);
        padding-top: var(--tablet-card-padding-top);
        padding-bottom: var(--tablet-card-padding-bottom);
        margin-bottom: var(--tablet-card-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .card {
        padding-left: var(--desktop-card-padding-left);
        padding-right: var(--desktop-card-padding-right);
        padding-top: var(--desktop-card-padding-top);
        padding-bottom: var(--desktop-card-padding-bottom);
        margin-bottom: var(--desktop-card-margin-bottom);
    }
}