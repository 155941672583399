:root {

    // ============================= offer-card-card ================================

    --mobile-offer-card-card-padding-left: var(--mobile-cyan-spacing);
    --mobile-offer-card-card-padding-right: var(--mobile-cyan-spacing);

    --tablet-offer-card-card-padding-left: var(--tablet-cyan-spacing);
    --tablet-offer-card-card-padding-right: var(--tablet-cyan-spacing);

    --desktop-offer-card-card-padding-left: var(--desktop-cyan-spacing);
    --desktop-offer-card-card-padding-right: var(--desktop-cyan-spacing);

}