// Mobile
.button-addressunitsugestions {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    width: 100%;
    display: flex;
    font-family: var(--button-addressunitsugestions-font-family);
    font-size: var(--mobile-button-addressunitsugestions-font-size);
    line-height: var(--mobile-button-addressunitsugestions-line-height);
    letter-spacing: var(--mobile-button-addressunitsugestions-letter-spacing);
    font-weight: var(--mobile-button-addressunitsugestions-font-weight);
    gap: var(--mobile-button-addressunitsugestions-gap);
    padding-left: var(--mobile-button-addressunitsugestions-padding-left);
    padding-right: var(--mobile-button-addressunitsugestions-padding-right);
    padding-top: var(--mobile-button-addressunitsugestions-padding-top);
    padding-bottom: var(--mobile-button-addressunitsugestions-padding-bottom);
    border-bottom: 1px solid var(--container-addressunitsugestions-border-color);
}
.button-addressunitsugestions:hover {
    background-color: var(--button-addressunitsugestions-background-color-hover);
}
.button-addressunitsugestions:active {
    background-color: var(--button-addressunitsugestions-background-color-active);
}
.button-addressunitsugestions-last {
    border-bottom: 0px;
    border-radius: 0 0 10px 10px;
}

.button-addressunitsugestions svg {
    margin-top: auto;
    margin-bottom: auto;
}

.suggestion-container-addressunitsugestions {
    text-align: left;
}

// Tablet
@media (min-width: 768px) { 
    .button-addressunitsugestions {
        font-size: var(--tablet-button-addressunitsugestions-font-size);
        line-height: var(--tablet-button-addressunitsugestions-line-height);
        letter-spacing: var(--tablet-button-addressunitsugestions-letter-spacing);
        font-weight: var(--tablet-button-addressunitsugestions-font-weight);
        gap: var(--tablet-button-addressunitsugestions-gap);
        padding-left: var(--tablet-button-addressunitsugestions-padding-left);
        padding-right: var(--tablet-button-addressunitsugestions-padding-right);
        padding-top: var(--tablet-button-addressunitsugestions-padding-top);
        padding-bottom: var(--tablet-button-addressunitsugestions-padding-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .button-addressunitsugestions {
        gap: var(--dektop-button-addressunitsugestions-gap);
        padding-left: var(--desktop-button-addressunitsugestions-padding-left);
        padding-right: var(--desktop-button-addressunitsugestions-padding-right);
        padding-top: var(--desktop-button-addressunitsugestions-padding-top);
        padding-bottom: var(--desktop-button-addressunitsugestions-padding-bottom);
    }
}