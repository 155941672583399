// Mobile
.show-more {
    border: none;
    width: 100%;
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0);
    color: var(--showmore-text-color);
    font-family: var(--showmore-font-family);
    font-size: var(--mobile-showmore-font-size);
    line-height: var(--mobile-showmore-line-height);
    letter-spacing: var(--mobile-showmore-letter-spacing);
    font-weight: var(--mobile-showmore-font-weight);
    margin-top: var(--mobile-showmore-margin-top);
    margin-bottom: var(--mobile-showmore-margin-bottom);
}
.show-more:hover {
    color: var(--showmore-text-color-hover);
}
.show-more:active {
    color: var(--showmore-text-color-active);
}

.primary-show-more {
    border: none;
    width: 100%;
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-showmore-text-color);
    font-family: var(--primary-showmore-font-family);
    font-size: var(--mobile-primary-showmore-font-size);
    line-height: var(--mobile-primary-showmore-line-height);
    letter-spacing: var(--mobile-primary-showmore-letter-spacing);
    font-weight: var(--mobile-primary-showmore-font-weight);
    text-align: left;
}
.primary-show-more:hover {
    color: var(--primary-showmore-text-color-hover);
}
.primary-show-more:active {
    color: var(--primary-showmore-text-color-active);
}
.primary-show-more-margin {
    margin-bottom: var(--mobile-primary-showmore-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .show-more {
        font-size: var(--tablet-showmore-font-size);
        line-height: var(--tablet-showmore-line-height);
        letter-spacing: var(--tablet-showmore-letter-spacing);
        font-weight: var(--tablet-showmore-font-weight);
        margin-top: var(--tablet-showmore-margin-top);
        margin-bottom: var(--tablet-showmore-margin-bottom);
    }

    .primary-show-more {
        font-size: var(--tablet-primary-showmore-font-size);
        line-height: var(--tablet-primary-showmore-line-height);
        letter-spacing: var(--tablet-primary-showmore-letter-spacing);
        font-weight: var(--tablet-primary-showmore-font-weight);
    }
    .primary-show-more-margin {
        margin-bottom: var(--tablet-primary-showmore-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .show-more {
        margin-top: var(--desktop-showmore-margin-top);
        margin-bottom: var(--desktop-showmore-margin-bottom);
    }

    .primary-show-more-margin {
        margin-bottom: var(--desktop-primary-showmore-margin-bottom);
    }
}