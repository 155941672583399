:root {

    // ============================================= Label ========================================

    // Colors
    --datepicker-label-datepicker-text-color: var(--label-text-color);

    // Fonts
    --datepicker-label-datepicker-font-family: var(--font-600);

    // Mobile Fonts
    --mobile-datepicker-label-datepicker-font-size: var(--mobile-input-font-size);
    --mobile-datepicker-label-datepicker-line-height: var(--mobile-input-line-height);
    --mobile-datepicker-label-datepicker-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-datepicker-label-datepicker-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-datepicker-label-datepicker-font-size: var(--tablet-input-font-size);
    --tablet-datepicker-label-datepicker-line-height: var(--tablet-input-line-height);
    --tablet-datepicker-label-datepicker-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-datepicker-label-datepicker-font-weight: var(--tablet-input-font-weight);

    // Margins
    --mobile-label-datepicker-margin-bottom: var(--mobile-blue-spacing);
    --tablet-label-datepicker-margin-bottom: var(--tablet-pink-spacing);
    --desktop-label-datepicker-margin-bottom: var(--desktop-pink-spacing);

    // ======================================= Dropdown Layout ====================================

    // Mobile Gap
    --mobile-datepicker-dropdown-layout-datepicker-gap: var(--mobile-green-spacing);

    // Tablet Gap
    --tablet-datepicker-dropdown-layout-datepicker-gap: var(--tablet-cyan-spacing);

    // Desktop Gap
    --desktop-datepicker-dropdown-layout-datepicker-gap: var(--desktop-cyan-spacing);

    // ======================================= Dropdown Error =====================================

    --datepicker-dropdown-error-datepicker-text-color: var(--error-text-color);
    --datepicker-dropdown-error-datepicker-border-color: var(--fail-state);

    // ======================================== Error Message =====================================

    // Color
    --datepicker-error-message-datepicker-text-color: var(--error-text-color);

    // Mobile Margins
    --mobile-datepicker-error-message-datepicker-margin-left: var(--mobile-cyan-spacing);
    --mobile-datepicker-error-message-datepicker-margin-top: var(--mobile-blue-spacing);
    
    // Tablet Margins
    --tablet-datepicker-error-message-datepicker-margin-left: var(--tablet-cyan-spacing);
    --tablet-datepicker-error-message-datepicker-margin-top: var(--tablet-blue-spacing);
    
    // Dektop Margins
    --desktop-datepicker-error-message-datepicker-margin-left: var(--desktop-cyan-spacing);
    --desktop-datepicker-error-message-datepicker-margin-top: var(--desktop-blue-spacing);

    // ========================================= Label Text ======================================

    // Margin
    --mobile-datepicker-label-text-margin-left: var(--mobile-blue-spacing);
    --tablet-datepicker-label-text-margin-left: var(--tablet-blue-spacing);
    --desktop-datepicker-label-text-margin-left: var(--desktop-blue-spacing);
}