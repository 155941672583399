// Mobile
.title-confirmaddressdisplay {
    margin: 0;
    color: var(--title-confirmaddressdisplay-text-color);
    font-family: var(--font-600);
    font-size: var(--mobile-title-confirmaddressdisplay-font-size);
    line-height: var(--mobile-title-confirmaddressdisplay-line-height);
    letter-spacing: var(--mobile-title-confirmaddressdisplay-letter-spacing);
    font-weight: 600;
    margin-left: var(--mobile-title-confirmaddressdisplay-margin-left);
}

.container-confirmaddressdisplay {
    border-radius: 10px;
    margin-bottom: var(--mobile-container-confirmaddressdisplay-margin-bottom);
    border: 2px solid var(--container-confirmaddressdisplay-border-color);
    padding-top: var(--mobile-container-confirmaddressdisplay-padding-top);
    padding-bottom: var(--mobile-container-confirmaddressdisplay-padding-bottom);
    padding-left: var(--mobile-container-confirmaddressdisplay-padding-left);
    padding-right: var(--mobile-container-confirmaddressdisplay-padding-right);
}

.container-confirmaddressdisplay-flexed {
    display: flex;
    justify-content: space-between;
}

.sub-title-confirmaddressdisplay {
    margin: 0;
    display: inline-block;
    color: var(--sub-title-confirmaddressdisplay-text-color);
    font-family: var(--sub-title-confirmaddressdisplay-font-family);
    font-size: var(--mobile-sub-title-confirmaddressdisplay-font-size);
    line-height: var(--mobile-sub-title-confirmaddressdisplay-line-height);
    letter-spacing: var(--mobile-sub-title-confirmaddressdisplay-letter-spacing);
    font-weight: var(--mobile-sub-title-confirmaddressdisplay-font-weight);
}

.text-confirmaddressdisplay {
    margin: 0;
    display: inline-block;
    color: var(--text-confirmaddressdisplay-text-color);
    font-family: var(--text-confirmaddressdisplay-font-family);
    font-size: var(--mobile-text-confirmaddressdisplay-font-size);
    line-height: var(--mobile-text-confirmaddressdisplay-line-height);
    letter-spacing: var(--mobile-text-confirmaddressdisplay-letter-spacing);
    font-weight: var(--mobile-text-confirmaddressdisplay-font-weight);
}

.link-confirmaddressdisplay {
    color: var(--hyperlink-text-color);
    font-family: var(--sub-title-confirmaddressdisplay-font-family);
    font-size: var(--mobile-sub-title-confirmaddressdisplay-font-size);
    line-height: var(--mobile-sub-title-confirmaddressdisplay-line-height);
    letter-spacing: var(--mobile-sub-title-confirmaddressdisplay-letter-spacing);
    font-weight: var(--mobile-sub-title-confirmaddressdisplay-font-weight);
    text-decoration: underline;
}

// Tablet
@media (min-width: 768px) { 
    .title-confirmaddressdisplay {
        font-size: var(--tablet-title-confirmaddressdisplay-font-size);
        line-height: var(--tablet-title-confirmaddressdisplay-line-height);
        letter-spacing: var(--tablet-title-confirmaddressdisplay-letter-spacing);
        font-weight: 600;
        margin-left: var(--tablet-title-confirmaddressdisplay-margin-left);
    }

    .container-confirmaddressdisplay {
        margin-bottom: var(--tablet-container-confirmaddressdisplay-margin-bottom);
        padding-top: var(--tablet-container-confirmaddressdisplay-padding-top);
        padding-bottom: var(--tablet-container-confirmaddressdisplay-padding-bottom);
        padding-left: var(--tablet-container-confirmaddressdisplay-padding-left);
        padding-right: var(--tablet-container-confirmaddressdisplay-padding-right);
    }

    .sub-title-confirmaddressdisplay {
        font-size: var(--tablet-sub-title-confirmaddressdisplay-font-size);
        line-height: var(--tablet-sub-title-confirmaddressdisplay-line-height);
        letter-spacing: var(--tablet-sub-title-confirmaddressdisplay-letter-spacing);
        font-weight: var(--tablet-sub-title-confirmaddressdisplay-font-weight);
    }

    .text-confirmaddressdisplay {
        font-size: var(--tablet-text-confirmaddressdisplay-font-size);
        line-height: var(--tablet-text-confirmaddressdisplay-line-height);
        letter-spacing: var(--tablet-text-confirmaddressdisplay-letter-spacing);
        font-weight: var(--tablet-text-confirmaddressdisplay-font-weight);
    }

    .link-confirmaddressdisplay {
        font-size: var(--tablet-sub-title-confirmaddressdisplay-font-size);
        line-height: var(--tablet-sub-title-confirmaddressdisplay-line-height);
        letter-spacing: var(--tablet-sub-title-confirmaddressdisplay-letter-spacing);
        font-weight: var(--tablet-sub-title-confirmaddressdisplay-font-weight);
    }

}

// Desktop
@media (min-width: 1024px) { 
    .main-confirmaddressdisplay {
        min-width: 48%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    } 
    .title-confirmaddressdisplay {
        margin-left: var(--desktop-title-confirmaddressdisplay-margin-left);
    }

    .container-confirmaddressdisplay {
        flex-grow: 1;
        margin-bottom: var(--desktop-container-confirmaddressdisplay-margin-bottom);
        padding-top: var(--desktop-container-confirmaddressdisplay-padding-top);
        padding-bottom: var(--desktop-container-confirmaddressdisplay-padding-bottom);
        padding-left: var(--desktop-container-confirmaddressdisplay-padding-left);
        padding-right: var(--desktop-container-confirmaddressdisplay-padding-right);
    }
}