// Mobile
.label-datepicker {
    width: 100%;
    font-family: var(--datepicker-label-datepicker-font-family);
    color: var(--datepicker-label-datepicker-text-color);
    font-size: var(--mobile-datepicker-label-datepicker-font-size);
    line-height: var(--mobile-datepicker-label-datepicker-line-height);
    letter-spacing: var(--mobile-datepicker-label-datepicker-letter-spacing);
    font-weight: var(--mobile-datepicker-label-datepicker-font-weight);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--mobile-label-datepicker-margin-bottom);
}

.label-datepicker select {
    width: 100%;
}

.dropdown-layout-datepicker {
    display: flex;
    gap: var(--mobile-datepicker-dropdown-layout-datepicker-gap);
}

.dropdown-error-datepicker {
    color: var(--datepicker-dropdown-error-datepicker-text-color);
    border-color: var(--datepicker-dropdown-error-datepicker-border-color);
}

.error-message-datepicker {
    color: var(--datepicker-error-message-datepicker-text-color);
    margin-left: var(--mobile-datepicker-error-message-datepicker-margin-left);
    margin-top: var(--mobile-datepicker-error-message-datepicker-margin-top);
    margin-bottom: 0;
    display: flex;
}

.datepicker-label-text {
    margin-left: var(--mobile-datepicker-label-text-margin-left);
}

// Tablet
@media (min-width: 768px) { 
    .label-datepicker {
        font-size: var(--tablet-datepicker-label-datepicker-font-size);
        line-height: var(--tablet-datepicker-label-datepicker-line-height);
        letter-spacing: var(--tablet-datepicker-label-datepicker-letter-spacing);
        font-weight: var(--tablet-datepicker-label-datepicker-font-weight);
        margin-bottom: var(--tablet-label-datepicker-margin-bottom);
    }

    .dropdown-layout-datepicker {
        gap: var(--tablet-datepicker-dropdown-layout-datepicker-gap);
    }

    .error-message-datepicker {
        margin-left: var(--tablet-datepicker-error-message-datepicker-margin-left);
        margin-top: var(--tablet-datepicker-error-message-datepicker-margin-top);
    }

    .datepicker-label-text {
        margin-left: var(--tablet-datepicker-label-text-margin-left);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .label-datepicker {
        margin-bottom: var(--desktop-label-datepicker-margin-bottom);
    }

    .dropdown-layout-datepicker {
        gap: var(--desktop-datepicker-dropdown-layout-datepicker-gap);
    }

    .error-message-datepicker {
        margin-left: var(--desktop-datepicker-error-message-datepicker-margin-left);
        margin-top: var(--desktop-datepicker-error-message-datepicker-margin-top);
    }

    .datepicker-label-text {
        margin-left: var(--desktop-datepicker-label-text-margin-left);
    }
}