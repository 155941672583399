:root {

    // ===================== No idea if these are still in us =====================

    // Address Field
    --mobile-address-label-font-size: var(--mobile-body-font-size);
    --mobile-address-label-line-height: var(--mobile-body-line-height);
    --tablet-address-label-font-size: var(--tablet-body-font-size);
    --tablet-address-label-line-height: var(--tablet-body-line-height);
    --address-label-font: var(--main-font);
    --address-label-text-color: var(--text-color);
    --address-label-font-weight: var(--normal-font-weight);
    --address-label-font-style: var(--normal-font-style);
    --address-error-weight: var(--bold-font-weight);
    --address-error-text-color: var(--error-text-color);
    --address-error-font: var(--bold-font);
    --address-label-font: var(--main-font);
    --address-field-container-font: var(--main-font);
    --addresses-container-background-color: white;
    --addresses-container-border-color: #000000;
    --addresses-buttons-divider-border-color: #000000;
    --addresses-buttons-divider-no-border-border-color: #000000;

    // ============================= Zip Container ==============================

    --mobile-address-field-state-zip-container-gap: var(--mobile-green-spacing);
    --tablet-address-field-state-zip-container-gap: var(--mobile-cyan-spacing);
    --desktop-address-field-state-zip-container-gap: var(--mobile-cyan-spacing);
    
}