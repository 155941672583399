:root {
    --input-blocker-amount-of-loading-texts: 0;
    --input-blocker-height-of-loading-texts: 46;
    --input-blocker-speed: 0s;
}

.input-blocker-loading-step-loading-animation {
    margin-top: var(--mobile-green-spacing);
    text-align: center;
    height: calc(var(--input-blocker-height-of-loading-texts) * 1px);
    color: var(--white);
    overflow: hidden;
}

.input-blocker-loading-step-loading-animation div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    animation: inputBlockerContainerTranslate calc(var(--input-blocker-speed) * var(--input-blocker-amount-of-loading-texts)) steps(var(--input-blocker-amount-of-loading-texts)) infinite;
}

.input-blocker-loading-step-loading-animation div h1 {
    margin-top: 2px;
    margin-bottom: 2px;
    flex-shrink: 0;
    width: 100%;
    white-space: nowrap;
    animation: inputBlockerTextTranslate calc(var(--input-blocker-speed)) infinite;
}

@keyframes inputBlockerContainerTranslate {
    100% {
      transform: translate(0, 0);
    }
    0% {
      transform: translate(0, calc(var(--input-blocker-amount-of-loading-texts) * var(--input-blocker-height-of-loading-texts) * -1px));
    }
}

@keyframes inputBlockerTextTranslate {
    100% {
      transform: translate(0, 0);
    }
    20%,
    0% {
      transform: translate(0, calc(var(--input-blocker-height-of-loading-texts) * -1px));
    }
}

// Tablet
@media (min-width: 768px) { 
    :root {
        --input-blocker-height-of-loading-texts: 54;
    }

    .loading-step-loading-animation {
        margin-top: var(--tablet-green-spacing);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .loading-step-loading-animation {
        margin-top: var(--desktop-green-spacing);
    }
}
  

  