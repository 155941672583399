:root {
    // ========================== Show More ==========================

    // Colors
    --showmore-text-color: var(--text-color);
    --showmore-text-color-hover: var(--hover-color);
    --showmore-text-color-active: var(--active-color);

    // Fonts
    --showmore-font-family: var(--font-600);

    // Mobile Fonts
    --mobile-showmore-font-size: var(--mobile-button-font-size);
    --mobile-showmore-line-height: var(--mobile-button-line-height);
    --mobile-showmore-letter-spacing: var(--mobile-button-letter-spacing);
    --mobile-showmore-font-weight: var(--mobile-button-font-weight);

    // Tablet/Desktop Fonts
    --tablet-showmore-font-size: var(--tablet-button-font-size);
    --tablet-showmore-line-height: var(--tablet-button-line-height);
    --tablet-showmore-letter-spacing: var(--tablet-button-letter-spacing);
    --tablet-showmore-font-weight: var(--tablet-button-font-weight);

    // Mobile Margins
    --mobile-showmore-margin-top: var(--mobile-cyan-spacing);
    --mobile-showmore-margin-bottom: var(--mobile-purple-spacing);

    // Tablet Margins
    --tablet-showmore-margin-top: var(--tablet-cyan-spacing);
    --tablet-showmore-margin-bottom: var(--tablet-purple-spacing);

    // Desktop Margins
    --desktop-showmore-margin-top: var(--desktop-cyan-spacing);
    --desktop-showmore-margin-bottom: var(--desktop-purple-spacing);

    // ====================== Primary Show More ======================

    // Colors
    --primary-showmore-text-color: var(--primary-color);
    --primary-showmore-text-color-hover: var(--hover-color);
    --primary-showmore-text-color-active: var(--active-color);

    // Font Family
    --primary-showmore-font-family: var(--bold-font);

    // Mobile Fonts
    --mobile-primary-showmore-font-size: var(--mobile-button-font-size);
    --mobile-primary-showmore-line-height: var(--mobile-button-line-height);
    --mobile-primary-showmore-letter-spacing: var(--mobile-button-letter-spacing);
    --mobile-primary-showmore-font-weight: var(--mobile-button-font-weight);

    // Tablet Fonts

    --tablet-primary-showmore-font-size: var(--tablet-button-font-size);
    --tablet-primary-showmore-line-height: var(--tablet-button-line-height);
    --tablet-primary-showmore-letter-spacing: var(--tablet-button-letter-spacing);
    --tablet-primary-showmore-font-weight: var(--tablet-button-font-weight);

    // Margins

    --mobile-primary-showmore-margin-bottom: var(--mobile-purple-spacing);
    --tablet-primary-showmore-margin-bottom: var(--tablet-purple-spacing);
    --desktop-primary-showmore-margin-bottom: var(--desktop-purple-spacing);

}