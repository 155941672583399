:root {

    // ============================= offer-text-container ================================

    --mobile-offer-text-container-margin-top: var(--mobile-cyan-spacing);
    --tablet-offer-text-container-margin-top: var(--tablet-cyan-spacing);
    --desktop-offer-text-container-margin-top: var(--desktop-cyan-spacing);
    --mobile-offer-text-container-margin-bottom: var(--mobile-cyan-spacing);
    --tablet-offer-text-container-margin-bottom: var(--tablet-cyan-spacing);
    --desktop-offer-text-container-margin-bottom: var(--desktop-cyan-spacing);

    // ========================== offer-text-prices-container ============================

    --mobile-offer-text-prices-container-gap: var(--mobile-blue-spacing);
    --tablet-offer-text-prices-container-gap: var(--tablet-blue-spacing);
    --desktop-offer-text-prices-container-gap: var(--desktop-blue-spacing);

    // =============================== offer-text-strike =================================

    --offer-text-strike-text-color: var(--fail-state);

}