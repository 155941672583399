:root {
    // ============================================= Disclosures Container Outer ========================================

    // Colors
    --disclosures-container-outer-border-color: var(--demo-form-gray);
    --disclosures-container-outer-background-color-scroll-thumb: var(--primary-color);
    --disclosures-container-outer-background-color-scroll-track: #F0F0F0;

    // Mobile Padding
    --mobile-disclosures-container-outer-padding-top: var(--mobile-pink-spacing);
    --mobile-disclosures-container-outer-padding-bottom: var(--mobile-pink-spacing);
    --mobile-disclosures-container-outer-padding-left: var(--mobile-cyan-spacing);
    --mobile-disclosures-container-outer-padding-right: 0;

    // Tablet Padding
    --tablet-disclosures-container-outer-padding-top: var(--tablet-pink-spacing);
    --tablet-disclosures-container-outer-padding-bottom: var(--tablet-pink-spacing);
    --tablet-disclosures-container-outer-padding-left: var(--tablet-cyan-spacing);
    --tablet-disclosures-container-outer-padding-right: 0;

    // Desktop Padding
    --desktop-disclosures-container-outer-padding-top: var(--desktop-pink-spacing);
    --desktop-disclosures-container-outer-padding-bottom: var(--desktop-pink-spacing);
    --desktop-disclosures-container-outer-padding-left: var(--desktop-cyan-spacing);
    --desktop-disclosures-container-outer-padding-right: 0;

    // Mobile Margins
    --mobile-disclosures-container-outer-margin-top: var(--mobile-cyan-spacing);
    --mobile-disclosures-container-outer-margin-bottom: var(--mobile-cyan-spacing);
    --mobile-disclosures-container-outer-margin-top-scroll-track: var(--mobile-cyan-spacing);
    --mobile-disclosures-container-outer-margin-bottom-scroll-track: var(--mobile-cyan-spacing);

    // Tablet Margins
    --tablet-disclosures-container-outer-margin-top: var(--tablet-cyan-spacing);
    --tablet-disclosures-container-outer-margin-bottom: var(--tablet-cyan-spacing);
    --tablet-disclosures-container-outer-margin-top-scroll-track: var(--tablet-cyan-spacing);
    --tablet-disclosures-container-outer-margin-bottom-scroll-track: var(--tablet-cyan-spacing);

    // Desktop Margins
    --desktop-disclosures-container-outer-margin-top: var(--desktop-cyan-spacing);
    --desktop-disclosures-container-outer-margin-bottom: var(--desktop-cyan-spacing);
    --desktop-disclosures-container-outer-margin-top-scroll-track: var(--desktop-cyan-spacing);
    --desktop-disclosures-container-outer-margin-bottom-scroll-track: var(--desktop-cyan-spacing);

    // Widths
    --mobile-disclosures-container-inner-width-scroll: var(--mobile-pink-spacing);
    --tablet-disclosures-container-inner-width-scroll: var(--tablet-pink-spacing);
    --desktop-disclosures-container-inner-width-scroll: var(--desktop-pink-spacing);

    // ============================================= Disclosures Container Inner =======================================

    // Margins
    --mobile-disclosures-container-inner-margin-right: var(--mobile-pink-spacing);
    --tablet-disclosures-container-inner-margin-right: var(--tablet-pink-spacing);
    --desktop-disclosures-container-inner-margin-right: var(--desktop-pink-spacing);

    // =================================================== Disclosures Logo ============================================

    // Width
    --mobile-disclosures-container-logo-width: 130px;
    --tablet-disclosures-container-logo-width: 130px;
    --desktop-disclosures-container-logo-width: 130px;

    // Padding
    --mobile-disclosures-container-logo-padding-right: var(--mobile-disclosures-container-outer-width-scroll);
    --tablet-disclosures-container-logo-padding-right: var(--tablet-disclosures-container-outer-width-scroll);
    --desktop-disclosures-container-logo-padding-right: var(--desktop-disclosures-container-outer-width-scroll);

    // Margins
    --mobile-disclosures-container-logo-margin-bottom: var(--mobile-pink-spacing);
    --tablet-disclosures-container-logo-margin-bottom: var(--tablet-pink-spacing);
    --desktop-disclosures-container-logo-margin-bottom: var(--desktop-pink-spacing);

}