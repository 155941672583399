:root {

    // ==================== LegalText ====================

    // Colors
    --mobile-legaltext-text-legal-text-color: var(--text-color);

    // Fonts
    --mobile-legaltext-text-legal-font-family: var(--legal-font);
    
    // Mobile Fonts
    --mobile-legaltext-text-legal-font-size: var(--mobile-legal-font-size);
    --mobile-legaltext-text-legal-line-height: var(--mobile-legal-line-height);
    --mobile-legaltext-text-legal-letter-spacing: var(--mobile-legal-letter-spacing);
    --mobile-legaltext-text-legal-font-weight: var(--mobile-legal-font-weight);

    // Tablet Fonts
    --tablet-legaltext-text-legal-font-size: var(--tablet-legal-font-size);
    --tablet-legaltext-text-legal-line-height: var(--tablet-legal-line-height:);
    --tablet-legaltext-text-legal-letter-spacing: var(--tablet-legal-letter-spacing);
    --tablet-legaltext-text-legal-font-weight: var(--tablet-legal-font-weight);

}