:root {

    // ================================== subheading-text ================================

    --mobile-subheadingtext-text-subheading-margin-bottom: var(--mobile-green-spacing);
    --tablet-subheadingtext-text-subheading-margin-bottom: var(--tablet-cyan-spacing);
    --desktop-subheadingtext-text-subheading-margin-bottom: var(--desktop-cyan-spacing);

    // ============================ read-more-button-subheading ==========================

    // Color
    --read-more-button-subheading-text-color: var(--demo-form-gray);

    // Margins
    --mobile-read-more-button-subheading-margin-left: var(--mobile-green-spacing);
    --tablet-read-more-button-subheading-margin-left: var(--tablet-green-spacing);
    --desktop-read-more-button-subheading-margin-left: var(--desktop-green-spacing);
}