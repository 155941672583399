.disclosures-container-outer {
    margin-top: var(--mobile-disclosures-container-outer-margin-top);
    margin-bottom: var(--mobile-disclosures-container-outer-margin-bottom);
    padding-top: var(--mobile-disclosures-container-outer-padding-top);
    padding-bottom: var(--mobile-disclosures-container-outer-padding-bottom);
    padding-left: var(--mobile-disclosures-container-outer-padding-left);
    padding-right: var(--mobile-disclosures-container-outer-padding-right);
    border-radius: 19px;
    border: 2px solid var(--disclosures-container-outer-border-color);
    overflow: scroll;
    overflow-x: hidden;
    max-height: 274px;
}
.disclosures-container-outer::-webkit-scrollbar {
    width: var(--mobile-disclosures-container-outer-width-scroll);
}
.disclosures-container-outer::-webkit-scrollbar-thumb {
    border-radius: 79.5154px;
    background-color: var(--disclosures-container-outer-background-color-scroll-thumb);
}
.disclosures-container-outer::-webkit-scrollbar-track {
    margin-top: var(--mobile-disclosures-container-outer-margin-top-scroll-track);
    margin-bottom: var(--mobile-disclosures-container-outer-margin-bottom-scroll-track);
    background-color: var(--disclosures-container-outer-background-color-scroll-track);
}

.disclosures-container-inner {
    margin-right: var(--mobile-disclosures-container-inner-margin-right);
}

.disclosures-container-logo-container {
    width: 100%;
    padding-right: var(--mobile-disclosures-container-logo-padding-right);
    margin-bottom: var(--mobile-disclosures-container-logo-margin-bottom);
}
.disclosures-container-logo {
    width: var(--mobile-disclosures-container-logo-width);
    height: auto;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) { 
    .disclosures-container-outer {
        margin-top: var(--tablet-disclosures-container-outer-margin-top);
        margin-bottom: var(--tablet-disclosures-container-outer-margin-bottom);
        padding-top: var(--tablet-disclosures-container-outer-padding-top);
        padding-bottom: var(--tablet-disclosures-container-outer-padding-bottom);
        padding-left: var(--tablet-disclosures-container-outer-padding-left);
        padding-right: var(--tablet-disclosures-container-outer-padding-right);
    }
    .disclosures-container-outer::-webkit-scrollbar {
        width: var(--tablet-disclosures-container-outer-width-scroll);
    }
    .disclosures-container-outer::-webkit-scrollbar-track {
        margin-top: var(--tablet-disclosures-container-outer-margin-top-scroll-track);
        margin-bottom: var(--tablet-disclosures-container-outer-margin-bottom-scroll-track);
    }

    .disclosures-container-inner {
        margin-right: var(--tablet-disclosures-container-inner-margin-right);
    }

    .disclosures-container-logo-container {
        padding-right: var(--tablet-disclosures-container-logo-padding-right);
        margin-bottom: var(--tablet-disclosures-container-logo-margin-bottom);
    }
    .disclosures-container-logo {
        width: var(--tablet-disclosures-container-logo-width);
    }
}
@media (min-width: 1024px) { 
    .disclosures-container-outer {
        margin-top: var(--desktop-disclosures-container-outer-margin-top);
        margin-bottom: var(--desktop-disclosures-container-outer-margin-bottom);
        padding-top: var(--desktop-disclosures-container-outer-padding-top);
        padding-bottom: var(--desktop-disclosures-container-outer-padding-bottom);
        padding-left: var(--desktop-disclosures-container-outer-padding-left);
        padding-right: var(--desktop-disclosures-container-outer-padding-right);
    }
    .disclosures-container-outer::-webkit-scrollbar {
        width: var(--desktop-disclosures-container-outer-width-scroll);
    }
    .disclosures-container-outer::-webkit-scrollbar-track {
        margin-top: var(--desktop-disclosures-container-outer-margin-top-scroll-track);
        margin-bottom: var(--desktop-disclosures-container-outer-margin-bottom-scroll-track);
    }

    .disclosures-container-inner {
        margin-right: var(--desktop-disclosures-container-inner-margin-right);
    }

    .disclosures-container-logo-container {
        padding-right: var(--desktop-disclosures-container-logo-padding-right);
        margin-bottom: var(--desktop-disclosures-container-logo-margin-bottom);
    }
    .disclosures-container-logo {
        width: var(--desktop-disclosures-container-logo-width);
    }
}