:root {

    // ================================= Display Phone Number =================================

    // Fonts
    --display-phone-number-font-family: var(--font-600);

    // Colors
    --display-phone-number-text-color: var(--hyperlink-text-color);

    // Mobile Fonts
    --mobile-display-phone-number-font-size: var(--mobile-label-font-size);
    --mobile-display-phone-number-line-height: var(--mobile-label-line-height);
    --mobile-display-phone-number-letter-spacing: var(--mobile-label-letter-spacing);
    --mobile-display-phone-number-font-weight: var(--mobile-label-font-weight);

    // Tablet/Desktop Fonts
    --tablet-display-phone-number-font-size: var(--tablet-label-font-size);
    --tablet-display-phone-number-line-height: var(--tablet-label-line-height);
    --tablet-display-phone-number-letter-spacing: var(--tablet-label-letter-spacing);
    --tablet-display-phone-number-font-weight: var(--mobile-label-font-weight);

    // Mobile Margins
    --mobile-display-phone-number-margin-top: var(--mobile-green-spacing);
    --mobile-display-phone-number-margin-bottom: var(--mobile-purple-spacing);

    // Tablet Margins
    --tablet-display-phone-number-margin-top: var(--tablet-green-spacing);
    --tablet-display-phone-number-margin-bottom: var(--tablet-purple-spacing);

    // Desktop Margins
    --desktop-display-phone-number-margin-top: var(--desktop-green-spacing);
    --desktop-display-phone-number-margin-bottom: var(--desktop-purple-spacing);

}