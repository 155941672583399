:root {

    // ================================== switch-slider ================================

    // Background
    --switch-slider-border-color: var(--demo-form-gray);
    --switch-slider-background-color: var(--white);

    // Background:before
    --switch-slider-background-color-before: var(--demo-form-gray);

    // Checked Background
    --switch-slider-background-color-checked: var(--active-color);
    --switch-slider-border-color-checked: var(--active-color);

    // Checked Switch
    --switch-slider-background-color-switch-checked: var(--white);

}