:root {

    // =================================== Title ==================================

    // Colors
    --title-confirmaddressdisplay-text-color: var(--text-color);

    // Fonts
    --title-confirmaddressdisplay-font-family: var(--font-500);

    // Mobile Fonts
    --mobile-title-confirmaddressdisplay-font-size: var(--mobile-input-font-size);
    --mobile-title-confirmaddressdisplay-line-height: var(--mobile-input-line-height);
    --mobile-title-confirmaddressdisplay-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-title-confirmaddressdisplay-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-title-confirmaddressdisplay-font-size: var(--tablet-input-font-size);
    --tablet-title-confirmaddressdisplay-line-height: var(--tablet-input-line-height);
    --tablet-title-confirmaddressdisplay-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-title-confirmaddressdisplay-font-weight: var(--tablet-input-font-weight);

    // Margins
    --mobile-title-confirmaddressdisplay-margin-left: var(--mobile-pink-spacing);
    --tablet-title-confirmaddressdisplay-margin-left: var(--tablet-pink-spacing);
    --desktop-title-confirmaddressdisplay-margin-left: var(--desktop-pink-spacing);

    // ================================== Container ================================

    // Colors
    --container-confirmaddressdisplay-border-color: var(--default-border);

    // Margins
    --mobile-container-confirmaddressdisplay-margin-bottom: var(--mobile-pink-spacing);
    --tablet-container-confirmaddressdisplay-margin-bottom: var(--tablet-pink-spacing);
    --desktop-container-confirmaddressdisplay-margin-bottom: var(--desktop-pink-spacing);

    // Mobile Padding
    --mobile-container-confirmaddressdisplay-padding-top: var(--mobile-pink-spacing);
    --mobile-container-confirmaddressdisplay-padding-bottom: var(--mobile-pink-spacing);
    --mobile-container-confirmaddressdisplay-padding-left: var(--mobile-cyan-spacing);
    --mobile-container-confirmaddressdisplay-padding-right: var(--mobile-cyan-spacing);
    
    // Tablet Padding
    --tablet-container-confirmaddressdisplay-padding-top: var(--tablet-pink-spacing);
    --tablet-container-confirmaddressdisplay-padding-bottom: var(--tablet-pink-spacing);
    --tablet-container-confirmaddressdisplay-padding-left: var(--tablet-cyan-spacing);
    --tablet-container-confirmaddressdisplay-padding-right: var(--tablet-cyan-spacing);
    
    // Desktop Padding
    --desktop-container-confirmaddressdisplay-padding-top: var(--desktop-pink-spacing);
    --desktop-container-confirmaddressdisplay-padding-bottom: var(--desktop-pink-spacing);
    --desktop-container-confirmaddressdisplay-padding-left: var(--desktop-cyan-spacing);
    --desktop-container-confirmaddressdisplay-padding-right: var(--desktop-cyan-spacing);

    // ================================= Sub Title ================================

    // Colors
    --sub-title-confirmaddressdisplay-text-color: var(--text-color);

    // Fonts
    --sub-title-confirmaddressdisplay-font-family: var(--font-500);

    // Mobile Fonts
    --mobile-sub-title-confirmaddressdisplay-font-size: var(--mobile-input-font-size);
    --mobile-sub-title-confirmaddressdisplay-line-height: var(--mobile-input-line-height);
    --mobile-sub-title-confirmaddressdisplay-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-sub-title-confirmaddressdisplay-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-sub-title-confirmaddressdisplay-font-size: var(--tablet-input-font-size);
    --tablet-sub-title-confirmaddressdisplay-line-height: var(--tablet-input-line-height);
    --tablet-sub-title-confirmaddressdisplay-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-sub-title-confirmaddressdisplay-font-weight: var(--tablet-input-font-weight);

    // =================================== Text ===================================

    // Colors
    --text-confirmaddressdisplay-text-color: var(--text-color);

    // Fonts
    --text-confirmaddressdisplay-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-text-confirmaddressdisplay-font-size: var(--mobile-body-font-size);
    --mobile-text-confirmaddressdisplay-line-height: var(--mobile-body-line-height);
    --mobile-text-confirmaddressdisplay-letter-spacing: var(--mobile-body-letter-spacing);
    --mobile-text-confirmaddressdisplay-font-weight: var(--mobile-body-font-weight);

    // Tablet/Desktop Fonts
    --tablet-text-confirmaddressdisplay-font-size: var(--tablet-body-font-size);
    --tablet-text-confirmaddressdisplay-line-height: var(--tablet-body-line-height);
    --tablet-text-confirmaddressdisplay-letter-spacing: var(--tablet-body-letter-spacing);
    --tablet-text-confirmaddressdisplay-font-weight: var(--tablet-body-font-weight);


}