// Mobile
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color);
    color: var(--white);
    text-align: center;
}
.error-page-fc {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #0021f0;
    color: var(--white);
    text-align: center;
}
.error-page .bodytext {
    color: var(--white);
    margin-left: auto;
    margin-right: auto;
    width: 83vw;
}

.error-page-super-header {
    margin-bottom: var(--mobile-error-page-super-header-margin);
    margin-left: auto;
    margin-right: auto;
    width: 83vw;
}
.error-sub-fc{
    color: #fff;
    width: 80%;
}
.error-sub-container{
    display: flex;
    justify-content: center;
}

.error-page-image {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 60vh;
}

// Tablet
@media (min-width: 768px) { 
    .error-page-super-header {
        margin-bottom: var(--tablet-error-page-super-header-margin);
        width: 74vw;
    }
    .error-page .bodytext {
        width: 74vw;
    }

    .error-page-image {
        height: 70vh;
    }
}

// Desktop
@media (min-width: 1024px) { 
    .error-page-super-header {
        margin-bottom: var(--desktop-error-page-super-header-margin);
    }
    .error-page .bodytext {
        width: 36vw;
    }

    .error-page-image {
        height: 66vh;
    }
}