// Mobile
.text-subheading {
    margin-bottom: var(--mobile-subheadingtext-text-subheading-margin-bottom);
}

.read-more-button-subheading {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: var(--read-more-button-subheading-text-color);
    padding: 0;
    margin-left: var(--mobile-read-more-button-subheading-margin-left);
}

// Tablet
@media (min-width: 768px) { 
    .text-subheading {
        margin-bottom: var(--tablet-subheadingtext-text-subheading-margin-bottom);
    }

    .read-more-button-subheading {
        margin-left: var(--tablet-read-more-button-subheading-margin-left);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .text-subheading {
        margin-bottom: var(--desktop-subheadingtext-text-subheading-margin-bottom);
    }

    .read-more-button-subheading {
        margin-left: var(--desktop-read-more-button-subheading-margin-left);
    }
}