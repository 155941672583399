// Mobile
.text-bold {
    font-family: var(--bold-text-bold-font-family);
    font-weight: var(--bold-text-bold-font-weight);
}

// Tablet
@media (min-width: 768px) { 

}

// Desktop
@media (min-width: 1024px) { 

}