:root {

    // ================================== Label Textield ==================================

    // Colors
    --textfield-label-textield-text-color: var(--label-text-color);

    // Fonts
    --textfield-label-textield-font-family: var(--font-600);

    // Mobile Fonts
    --mobile-textfield-label-textield-font-size: var(--mobile-label-font-size);
    --mobile-textfield-label-textield-line-height: var(--mobile-label-line-height);
    --mobile-textfield-label-textield-letter-spacing: var(--mobile-label-letter-spacing);
    --mobile-textfield-label-textield-font-weight: var(--mobile-label-font-weight);

    // Mobile Margins
    --mobile-textfield-label-textield-margin-bottom: var(--mobile-blue-spacing);

    // Tablet/Desktop Fonts
    --tablet-textfield-label-textield-font-size: var(--tablet-label-font-size);
    --tablet-textfield-label-textield-line-height: var(--tablet-label-line-height);
    --tablet-textfield-label-textield-letter-spacing: var(--tablet-label-letter-spacing);
    --tablet-textfield-label-textield-font-weight: var(--tablet-label-font-weight);

    // Tablet Margins
    --tablet-textfield-label-textield-margin-bottom: var(--tablet-pink-spacing);

    // Desktop Margins
    --desktop-textfield-label-textield-margin-bottom: var(--desktop-pink-spacing);

    // ================================= Sublabel Textield ================================

    --mobile-textfield-sublabel-textfield-margin-bottom: var(--mobile-blue-spacing);
    --tablet-textfield-sublabel-textfield-margin-bottom: var(--tablet-blue-spacing);
    --desktop-textfield-sublabel-textfield-margin-bottom: var(--desktop-blue-spacing);

     // ================================= PoweredBy Textield ================================

     --mobile-textfield-poweredBy-textfield-margin-bottom: var(--mobile-pink-spacing);
     --tablet-textfield-poweredBy-textfield-margin-bottom: var(--tablet-pink-spacing);
     --desktop-textfield-poweredBy-textfield-margin-bottom: var(--desktop-pink-spacing);

    // ================================== Input Textield ==================================

    // Colors
    --textfield-input-textfield-text-color: var(--label-text-color);
    --textfield-input-textfield-text-color-placeholer: var(--placeholder-text-color);
    --textfield-input-textfield-border-color: var(--default-border);

    // Fonts
    --textfield-input-textfield-font-family: var(--font-500);
    --textfield-input-textfield-font-family-placeholer: var(--font-500);

    // Active State
    --textfield-input-textfield-border-color-active: var(--active-color);

    // Hover State
    --textfield-input-textfield-border-color-hover: var(--hover-color);

    // Mobile Fonts
    --mobile-textfield-input-textfield-font-size: var(--mobile-input-font-size);
    --mobile-textfield-input-textfield-line-height: var(--mobile-input-line-height);
    --mobile-textfield-input-textfield-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-textfield-input-textfield-font-weight: var(--mobile-input-font-weight);
    --mobile-textfield-input-textfield-font-size-placeholer: var(--mobile-input-font-size);
    --mobile-textfield-input-textfield-line-height-placeholer: var(--mobile-input-line-height);
    --mobile-textfield-input-textfield-letter-spacing-placeholer: var(--mobile-input-letter-spacing);
    --mobile-textfield-input-textfield-font-weight-placeholer: var(--mobile-input-font-weight);

    // Tablet Margins
    --tablet-textfield-input-textfield-margin-top: var(--tablet-blue-spacing);

    // Desktop Margins
    --desktop-textfield-input-textfield-margin-top: var(--desktop-blue-spacing);

    // Mobile Padding
    --mobile-textfield-input-textfield-padding-left: var(--mobile-cyan-spacing);
    --mobile-textfield-input-textfield-padding-right: var(--mobile-cyan-spacing);

    // Tablet Padding
    --tablet-textfield-input-textfield-padding-left: var(--tablet-cyan-spacing);
    --tablet-textfield-input-textfield-padding-right: var(--tablet-cyan-spacing);

    // Desktop Padding
    --desktop-textfield-input-textfield-padding-left: var(--desktop-cyan-spacing);
    --desktop-textfield-input-textfield-padding-right: var(--desktop-cyan-spacing);

    // ==================================== Error Text ===================================

    // Colors
    --textfield-error-text-textfield-text-color: var(--error-text-color);

    // ==================================== Input Error ==================================

    // Colors
    --textfield-input-error-textfield-text-color: var(--error-text-color);
    --textfield-input-error-textfield-border-color: var(--error-border);
    --textfield-input-error-textfield-text-color-placeholder: var(--error-border);

    // Mobile Margins
    --mobile-textfield-error-text-textfield-margin-top: var(--mobile-blue-spacing);
    --mobile-textfield-error-text-textfield-margin-left: var(--mobile-cyan-spacing);

    // Tablet Margins
    --tablet-textfield-error-text-textfield-margin-top: var(--tablet-blue-spacing);
    --tablet-textfield-error-text-textfield-margin-left: var(--tablet-cyan-spacing);

    // Desktop Margins
    --desktop-textfield-error-text-textfield-margin-top: var(--desktop-blue-spacing);
    --desktop-textfield-error-text-textfield-margin-left: var(--desktop-cyan-spacing);

    // ===================================== Dropdown ===================================

    --textfield-dropdown-textfield-border-color: var(--default-border);

    // ==================================== Label Text ==================================

    --mobile-textfield-label-text-textfield-margin-left: var(--mobile-blue-spacing);
    --tablet-textfield-label-text-textfield-margin-left: var(--tablet-blue-spacing);
    --desktop-textfield-label-text-textfield-margin-left: var(--desktop-blue-spacing);

    // ==================================== Label Text ==================================

    --mobile-popup-content-textfield-gap: var(--mobile-cyan-spacing);
    --tablet-popup-content-textfield-gap: 0;
    --desktop-popup-content-textfield-gap: 0;

    // ==================================== Info Button =================================

    --textfield-info-button-text-color-hover: var(--hover-color);
    --textfield-info-button-text-color-active: var(--active-color);

}