// Mobile
.display-phone-number {
    margin: 0px;
    display: block;
    text-align: center;
    font-family: var(--display-phone-number-font-family);
    color: var(--display-phone-number-text-color);
    font-size: var(--mobile-display-phone-number-font-size);
    line-height: var(--mobile-display-phone-number-line-height);
    letter-spacing: var(--mobile-display-phone-number-letter-spacing);
    font-weight: var(--mobile-display-phone-number-font-weight);
    padding-top: var(--mobile-display-phone-number-margin-top);
    padding-bottom: var(--mobile-display-phone-number-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .display-phone-number {
        font-size: var(--tablet-display-phone-number-font-size);
        line-height: var(--tablet-display-phone-number-line-height);
        letter-spacing: var(--tablet-display-phone-number-letter-spacing);
        font-weight: var(--tablet-display-phone-number-font-weight);
        padding-top: var(--tablet-display-phone-number-margin-top);
        padding-bottom: var(--tablet-display-phone-number-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .display-phone-number {
        padding-top: var(--desktop-display-phone-number-margin-top);
        padding-bottom: var(--desktop-display-phone-number-margin-bottom);
    }
}