:root {

    // =================================== Label Text ==================================

    // Colors
    --bodytext-labeltext-text-color: var(--text-color);

    // Fonts
    --bodytext-labeltext-font-family: var(--font-600);

    // Mobile Fonts
    --mobile-labeltext-body-text-font-size: var(--mobile-input-font-size);
    --mobile-labeltext-body-text-line-height: var(--mobile-input-line-height);
    --mobile-labeltext-body-text-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-labeltext-body-text-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop
    --tablet-labeltext-body-text-font-size: var(--tablet-input-font-size);
    --tablet-labeltext-body-text-line-height: var(--tablet-input-line-height);
    --tablet-labeltext-body-text-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-labeltext-body-text-font-weight: var(--tablet-input-font-weight);

}