:root {

    // ================================================ Button ================================================

    // Fonts
    --button-addresssugestions-font-family: var(--font-500);

    // Mobile Fonts
    --mobile-button-addresssugestions-font-size: var(--mobile-input-font-size);
    --mobile-button-addresssugestions-line-height: var(--mobile-input-line-height);
    --mobile-button-addresssugestions-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-button-addresssugestions-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-button-addresssugestions-font-size: var(--tablet-input-font-size);
    --tablet-button-addresssugestions-line-height: var(--tablet-input-line-height);
    --tablet-button-addresssugestions-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-button-addresssugestions-font-weight: var(--tablet-input-font-weight);

    // Mobile Padding/Gaps
    --mobile-button-addresssugestions-gap: var(--mobile-pink-spacing);
    --mobile-button-addresssugestions-padding-left: var(--mobile-pink-spacing);
    --mobile-button-addresssugestions-padding-right: var(--mobile-pink-spacing);
    --mobile-button-addresssugestions-padding-top: var(--mobile-blue-spacing);
    --mobile-button-addresssugestions-padding-bottom: var(--mobile-green-spacing);

    // Tablet Padding/Gaps
    --tablet-button-addresssugestions-gap: var(--tablet-pink-spacing);
    --tablet-button-addresssugestions-padding-left: var(--tablet-pink-spacing);
    --tablet-button-addresssugestions-padding-right: var(--tablet-pink-spacing);
    --tablet-button-addresssugestions-padding-top: var(--tablet-blue-spacing);
    --tablet-button-addresssugestions-padding-bottom: var(--tablet-green-spacing);

    // Desktop Padding/Gaps
    --dektop-button-addresssugestions-gap: var(--desktop-pink-spacing);
    --desktop-button-addresssugestions-padding-left: var(--desktop-pink-spacing);
    --desktop-button-addresssugestions-padding-right: var(--desktop-pink-spacing);
    --desktop-button-addresssugestions-padding-top: var(--desktop-blue-spacing);
    --desktop-button-addresssugestions-padding-bottom: var(--desktop-green-spacing);

    // Hover/Active
    --button-addresssugestions-background-color-hover: #f0f0f0;
    --button-addresssugestions-background-color-active: #f0f0f0;

    // ============================================== C0ntainer ==============================================

    --container-addresssugestions-border-color: var(--default-border);

    // Margins
    --mobile-containter-addresssugestions-margin-bottom: var(--mobile-blue-spacing);
    --tablet-containter-addresssugestions-margin-bottom: var(--tablet-pink-spacing);
    --desktop-containter-addresssugestions-margin-bottom: var(--desktop-pink-spacing);

}