// Mobile
.text-label {
    color: var(--bodytext-labeltext-text-color);
    font-family: var(--bodytext-labeltext-font-family);
    font-size: var(--mobile-labeltext-body-text-font-size);
    line-height: var(--mobile-labeltext-body-text-line-height);
    letter-spacing: var(--mobile-labeltext-body-text-letter-spacing);
    font-weight: var(--mobile-labeltext-body-text-font-weight);
    padding: 0;
    margin: 0;
}

// Tablet
@media (min-width: 768px) { 
    .text-label {
        font-size: var(--tablet-labeltext-body-text-font-size);
        line-height: var(--tablet-labeltext-body-text-line-height);
        letter-spacing: var(--tablet-labeltext-body-text-letter-spacing);
        font-weight: var(--tablet-labeltext-body-text-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 

}