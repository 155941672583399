:root {

    // ================================== Radio Menu ==================================

    // Colors
    --radio-menu-label-text-color: var(--text-color);
    --radio-menu-accent-color: var(--primary-color);

    // Font
    --radio-menu-label-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-radio-menu-label-body-text-font-size: var(--mobile-body-font-size);
    --mobile-radio-menu-label-body-text-line-height: var(--mobile-body-line-height);
    --mobile-radio-menu-label-body-text-letter-spacing: var(--mobile-body-letter-spacing);
    --mobile-radio-menu-label-body-text-font-weight: var(--mobile-body-font-weight);

    // Tablet/Desktop Fonts
    --tablet-radio-menu-label-font-size: var(--tablet-body-font-size);
    --tablet-radio-menu-label-line-height: var(--tablet-body-line-height);
    --tablet-radio-menu-label-letter-spacing: var(--tablet-body-letter-spacing);
    --tablet-radio-menu-label-font-weight: var(--tablet-body-font-weight);

    // Gaps
    --mobile-radio-menu-label-gap: var(--mobile-pink-spacing);
    --tablet-radio-menu-label-gap: var(--tablet-pink-spacing);
    --desktop-radio-menu-label-gap: var(--desktop-pink-spacing);

    // Mobile Margins
    --mobile-radio-menu-label-margin-top: var(--mobile-pink-spacing);
    --mobile-radio-menu-label-margin-right: var(--desktop-pink-spacing);

    // Tablet Margins
    --tablet-radio-menu-label-margin-top: var(--mobile-pink-spacing);
    --tablet-radio-menu-label-margin-right: var(--desktop-pink-spacing);

    // Desktop Margin
    --desktop-radio-menu-label-margin-top: var(--mobile-pink-spacing);
    --desktop-radio-menu-label-margin-right: var(--desktop-pink-spacing);

}