:root {

    // =================================== Card ==================================

    //Colors
    --card-border-color: var(--demo-form-gray);

    // Mobile Padding
    --mobile-card-padding-left: var(--mobile-cyan-spacing);
    --mobile-card-padding-right: var(--mobile-cyan-spacing);
    --mobile-card-padding-top: var(--mobile-pink-spacing);
    --mobile-card-padding-bottom: var(--mobile-pink-spacing);

    // Tablet Padding
    --tablet-card-padding-left: var(--tablet-cyan-spacing);
    --tablet-card-padding-right: var(--tablet-cyan-spacing);
    --tablet-card-padding-top: var(--tablet-pink-spacing);
    --tablet-card-padding-bottom: var(--tablet-pink-spacing);

    // Desktop Padding
    --desktop-card-padding-left: var(--desktop-cyan-spacing);
    --desktop-card-padding-right: var(--desktop-cyan-spacing);
    --desktop-card-padding-top: var(--desktop-pink-spacing);
    --desktop-card-padding-bottom: var(--desktop-pink-spacing);

    // Margins
    --mobile-card-margin-bottom: var(--mobile-pink-spacing);
    --tablet-card-margin-bottom: var(--tablet-pink-spacing);
    --desktop-card-margin-bottom: var(--desktop-pink-spacing);

}