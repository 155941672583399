// Mobile
.blocker-popup {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(65, 66, 78, 0.65);
    z-index: 40;
}
.popup {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    bottom: 0;
    left: 0;
    width: 100vw;
    max-height: 90vh;
    overflow: scroll;
    z-index: 50;
    padding-top: var(--mobile-popup-padding-top);
    background-color: var(--popup-background-color);
    border-radius: 30px 30px 0px 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
}

.popup-content-textfield .bodytext {
    text-align: center;
}
.popup::-webkit-scrollbar {
    display: none;
}
.container-popup {
    width: 85%;
}

// Tablet
@media (min-width: 768px) { 
    .popup {
        padding-top: var(--tablet-popup-padding-top);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        width: 550px;
        max-height: none;
    }
    .header-popup {
        width: 100%;
        text-align: center;
        padding-bottom: var(--tablet-header-popup-padding-bottom);
        margin-bottom: var(--tablet-header-popup-margin-bottom);
        border-bottom: 2px solid var(--header-popup-border-color);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .popup {
        padding-top: var(--desktop-popup-padding-top);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 30px;
        width: 550px;
    }
    .header-popup {
        width: 100%;
        text-align: center;
        padding-bottom: var(--desktop-header-popup-padding-bottom);
        margin-bottom: var(--desktop-header-popup-margin-bottom);
    }
}