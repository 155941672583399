// Mobile
.label-dropdown {
    width: 100%;
    font-family: var(--dropdown-label-dropdown-font-family);
    color: var(--dropdown-label-dropdown-text-color);
    font-size: var(--mobile-dropdown-label-dropdown-font-size);
    line-height: var(--mobile-dropdown-label-dropdown-line-height);
    letter-spacing: var(--mobile-dropdown-label-dropdown-letter-spacing);
    font-weight: var(--mobile-dropdown-label-dropdown-font-weight);
    display: flex;
    flex-direction: column;
    margin-bottom: var(--mobile-dropdown-label-dropdown-margin-bottom);
}

.select-dropdown {
    background: transparent;
    background-image: url("./Dropdown-Arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - var(--mobile-dropdown-select-dropdown-padding-right));
    background-position-y: 50%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 54px;
    width: 32%;
    font-family: var(--dropdown-select-dropdown-font-family);
    color: var(--dropdown-select-dropdown-text-color);
    font-size: var(--mobile-dropdown-select-dropdown-font-size);
    line-height: var(--mobile-dropdown-select-dropdown-line-height);
    letter-spacing: var(--mobile-dropdown-select-dropdown-letter-spacing);
    font-weight: var(--mobile-dropdown-select-dropdown-font-weight);
    border: 2px solid var(--dropdown-select-dropdown-border-color);
    border-radius: 10px;
    padding-left: var(--mobile-dropdown-select-dropdown-padding-left);
    padding-right: var(--mobile-dropdown-select-dropdown-padding-right);
}
.select-dropdown:active {
    color: var(--dropdown-select-dropdown-text-color-active);
    border-color: var(--dropdown-select-dropdown-border-active);
}
.select-dropdown:hover {
    color: var(--dropdown-select-dropdown-text-color-hover);
    border-color: var(--dropdown-select-dropdown-border-hover);
}
.select-dropdown:focus{
    outline: none;
}

.validation-message-dropdown {
    color: var(--validation-message-dropdown-text-color);
    margin-top: var(--mobile-validation-message-dropdown-margin-top);
    margin-left: var(--mobile-validation-message-dropdown-margin-left);
    margin-bottom: 0;
}
.select-dropdown-error {
    color: var(--select-dropdown-error-text-color);
    border-color: var(--select-dropdown-error-border-color);
}
.select-dropdown-label {
    margin: 0;
    margin-left: var(--mobile-select-dropdown-label-margin-left);
}

.select-dropdown-full-width {
    width: 100%;
}

.select-dropdown-no-bottom-margin {
    margin-bottom: 0;
}

// Tablet
@media (min-width: 768px) { 
    .label-dropdown {
        font-size: var(--tablet-dropdown-label-dropdown-font-size);
        line-height: var(--tablet-dropdown-label-dropdown-line-height);
        letter-spacing: var(--tablet-dropdown-label-dropdown-letter-spacing);
        font-weight: var(--tablet-dropdown-label-dropdown-font-weight);
        margin-bottom: var(--tablet-dropdown-label-dropdown-margin-bottom);
    }

    .select-dropdown {
        margin-top: var(--tablet-dropdown-select-dropdown-margin-top);
        font-size: var(--tablet-dropdown-select-dropdown-font-size);
        line-height: var(--tablet-dropdown-select-dropdown-line-height);
        letter-spacing: var(--tablet-dropdown-select-dropdown-letter-spacing);
        font-weight: var(--tablet-dropdown-select-dropdown-font-weight);
        padding-left: var(--tablet-dropdown-select-dropdown-padding-left);
        padding-right: var(--tablet-dropdown-select-dropdown-padding-right);
    }

    .validation-message-dropdown {
        margin-top: var(--tablet-validation-message-dropdown-margin-top);
        margin-left: var(--tablet-validation-message-dropdown-margin-left);
    }

    .select-dropdown-label {
        margin-left: var(--tablet-select-dropdown-label-margin-left);
    }

    .select-dropdown-no-bottom-margin {
        margin-bottom: 0;
    }
}

// Desktop
@media (min-width: 1024px) { 
    .label-dropdown {
        margin-bottom: var(--desktop-dropdown-label-dropdown-margin-bottom);
    }

    .select-dropdown {
        margin-top: var(--desktop-dropdown-select-dropdown-margin-top);
        padding-left: var(--desktop-dropdown-select-dropdown-padding-left);
        padding-right: var(--desktop-dropdown-select-dropdown-padding-right);
    }

    .validation-message-dropdown {
        margin-top: var(--desktop-validation-message-dropdown-margin-top);
        margin-left: var(--desktop-validation-message-dropdown-margin-left);
    }

    .select-dropdown-label {
        margin-left: var(--desktop-select-dropdown-label-margin-left);
    }

    .select-dropdown-no-bottom-margin {
        margin-bottom: 0;
    }
}