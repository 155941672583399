// Mobile
.label-textfield {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: var(--textfield-label-textield-font-family);
    color: var(--textfield-label-textield-text-color);
    font-size: var(--mobile-textfield-label-textield-font-size);
    line-height: var(--mobile-textfield-label-textield-line-height);
    letter-spacing: var(--mobile-textfield-label-textield-letter-spacing);
    font-weight: var(--mobile-textfield-label-textield-font-weight);
    margin-bottom: var(--mobile-textfield-label-textield-margin-bottom);
}

.sublabel-textfield {
    margin-bottom: var(--mobile-textfield-sublabel-textfield-margin-bottom);
}

.poweredBy-textfield {
    margin-bottom: var(--mobile-textfield-poweredBy-textfield-margin-bottom);
}

.input-textfield {
    height: 50px;
    border-radius: 10px;
    padding-top: 0;
    padding-bottom: 0;
    font-family: var(--textfield-input-textfield-font-family);
    color: var(--textfield-input-textfield-text-color);
    font-size: var(--mobile-textfield-input-textfield-font-size);
    line-height: var(--mobile-textfield-input-textfield-line-height);
    letter-spacing: var(--mobile-textfield-input-textfield-letter-spacing);
    font-weight: var(--mobile-textfield-input-textfield-font-weight);
    border: 2px solid var(--textfield-input-textfield-border-color);
    padding-left: var(--mobile-textfield-input-textfield-padding-left);
    padding-right: var(--mobile-textfield-input-textfield-padding-right);

}
.input-textfield::placeholder {
    font-family: var(--textfield-input-textfield-font-family-placeholer);
    color: var(--textfield-input-textfield-text-color-placeholer);
    font-size: var(--mobile-textfield-input-textfield-font-size-placeholer);
    line-height: var(--mobile-textfield-input-textfield-line-height-placeholer);
    letter-spacing: var(--mobile-textfield-input-textfield-letter-spacing-placeholer);
    font-weight: var(--mobile-textfield-input-textfield-font-weight-placeholer);
}
.input-textfield:active {
    border-color: var(--textfield-input-textfield-border-color-active);
}
.input-textfield:hover {
    border-color: var(--textfield-input-textfield-border-color-hover);
}
.input-textfield:focus{
    outline: none;
}

.input-dropdown-textfield {
    border-radius: 10px 10px 0 0;
}
.dropdown-textfield {
    border: 2px solid var(--textfield-dropdown-textfield-border-color);
    border-top: 0;
    border-radius: 0 0 10px 10px;
}

.error-text-textfield {
    color: var(--textfield-error-text-textfield-text-color);
    margin-top: var(--mobile-textfield-error-text-textfield-margin-top);
    margin-left: var(--mobile-textfield-error-text-textfield-margin-left);
    margin-bottom: 0;
    display: flex;
}

.input-error-textfield {
    color: var(--textfield-input-error-textfield-text-color);
    border-color: var(--textfield-input-error-textfield-border-color);
}
.input-error-textfield::placeholder {
    color: var(--textfield-input-error-textfield-text-color-placeholder);
}

.label-text-textfield {
    margin: 0;
    margin-left: var(--mobile-textfield-label-text-textfield-margin-left);
}

.popup-content-textfield {
    display: flex;
    flex-direction: column;
    gap: var(--mobile-popup-content-textfield-gap);
}

.textfield-info-button {
    background: rgba(0, 0, 0, 0);
    border: 0;
    color: var(--hyperlink-text-color);
}
.textfield-info-button:hover {
    color: var(--textfield-info-button-text-color-hover);
}
.textfield-info-button:active {
    color: var(--textfield-info-button-text-color-active);
}

.ssl-encryption-badge-container{
    display: flex;
    justify-content: center;
}
.ssl-encryption-badge-image{
    width: 103.618px;
    height: 34px;
}
.secure-shield-image{
    margin-right: 4px;
}

@media (min-width: 768px) { 
    .label-textfield {
        font-size: var(--tablet-textfield-label-textield-font-size);
        line-height: var(--tablet-textfield-label-textield-line-height);
        letter-spacing: var(--tablet-textfield-label-textield-letter-spacing);
        font-weight: var(--tablet-textfield-label-textield-font-weight);
        margin-bottom: var(--tablet-textfield-label-textield-margin-bottom);
    }

    .input-textfield {
        margin-top: var(--tablet-textfield-input-textfield-margin-top);
        padding-left: var(--tablet-textfield-input-textfield-padding-left);
        padding-right: var(--tablet-textfield-input-textfield-padding-right);

    }

    .error-text-textfield {
        margin-top: var(--tablet-textfield-error-text-textfield-margin-top);
        margin-left: var(--tablet-textfield-error-text-textfield-margin-left);
    }

    .sublabel-textfield {
        margin-bottom: var(--tablet-textfield-sublabel-textfield-margin-bottom);
    }

    .label-text-textfield {
        margin-left: var(--tablet-textfield-label-text-textfield-margin-left);
    }

    .popup-content-textfield {
        gap: var(--tablet-popup-content-textfield-gap);
    }

    .ssl-encryption-badge-image{
        width: 150px;
        height: 50px;
    }
}
@media (min-width: 1024px) { 
    .label-textfield {
        margin-bottom: var(--desktop-textfield-label-textield-margin-bottom);
    }

    .input-textfield {
        margin-top: var(--desktop-textfield-input-textfield-margin-top);
        padding-left: var(--desktop-textfield-input-textfield-padding-left);
        padding-right: var(--desktop-textfield-input-textfield-padding-right);
    }

    .error-text-textfield {
        margin-top: var(--desktop-textfield-error-text-textfield-margin-top);
        margin-left: var(--desktop-textfield-error-text-textfield-margin-left);
    }

    .sublabel-textfield {
        margin-bottom: var(--desktop-textfield-sublabel-textfield-margin-bottom);
    }

    .label-text-textfield {
        margin-left: var(--desktop-textfield-label-text-textfield-margin-left);
    }

    .popup-content-textfield {
        gap: var(--desktop-popup-content-textfield-gap);
    }
}