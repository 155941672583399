:root {

    // ================================= Label Dropdown =================================

    // Colors
    --dropdown-label-dropdown-text-color: var(--label-text-color);

    // Fonts
    --dropdown-label-dropdown-font-family: var(--font-600);
    
    // Mobile Fonts
    --mobile-dropdown-label-dropdown-font-size: var(--mobile-label-font-size);
    --mobile-dropdown-label-dropdown-line-height: var(--mobile-label-line-height);
    --mobile-dropdown-label-dropdown-letter-spacing: var(--mobile-label-letter-spacing);
    --mobile-dropdown-label-dropdown-font-weight: var(--mobile-label-font-weight);

    // Tablet/Desktop Fonts
    --tablet-dropdown-label-dropdown-font-size: var(--tablet-label-font-size);
    --tablet-dropdown-label-dropdown-line-height: var(--tablet-label-line-height);
    --tablet-dropdown-label-dropdown-letter-spacing: var(--tablet-label-letter-spacing);
    --tablet-dropdown-label-dropdown-font-weight: var(--tablet-label-font-weight);

    // Mobile Margins
    --mobile-dropdown-label-dropdown-margin-bottom: var(--mobile-blue-spacing);

    // Tablet Margins
    --tablet-dropdown-label-dropdown-margin-bottom: var(--tablet-pink-spacing);

    // Desktop Margins
    --desktop-dropdown-label-dropdown-margin-bottom: var(--desktop-pink-spacing);

    // ================================= Select Dropdown ================================

    // Colors
    --dropdown-select-dropdown-text-color: var(--text-color);
    --dropdown-select-dropdown-border-color: var(--default-border);

    // Fonts
    --dropdown-select-dropdown-font-family: var(--main-font);

    // Active State
    --dropdown-select-dropdown-text-color-active: var(--active-color);
    --dropdown-select-dropdown-border-active: var(--active-color);

    // Hover State
    --dropdown-select-dropdown-text-color-hover: var(--hover-color);
    --dropdown-select-dropdown-border-hover: var(--hover-color);

    // Mobile Fonts
    --mobile-dropdown-select-dropdown-font-size: var(--mobile-input-font-size);
    --mobile-dropdown-select-dropdown-line-height: var(--mobile-input-line-height);
    --mobile-dropdown-select-dropdown-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-dropdown-select-dropdown-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-dropdown-select-dropdown-font-size: var(--tablet-input-font-size);
    --tablet-dropdown-select-dropdown-line-height: var(--tablet-input-line-height);
    --tablet-dropdown-select-dropdown-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-dropdown-select-dropdown-font-weight: var(--tablet-input-font-weight);

    // Mobile Padding
    --mobile-dropdown-select-dropdown-padding-left: var(--mobile-cyan-spacing);
    --mobile-dropdown-select-dropdown-padding-right: var(--mobile-cyan-spacing);

    // Tablet Padding/Margin
    --tablet-dropdown-select-dropdown-padding-left: var(--tablet-cyan-spacing);
    --tablet-dropdown-select-dropdown-padding-right: var(--tablet-cyan-spacing);
    --tablet-dropdown-select-dropdown-margin-top: var(--tablet-blue-spacing);

    // Desktop Padding/Margin
    --desktop-dropdown-select-dropdown-padding-left: var(--desktop-cyan-spacing);
    --desktop-dropdown-select-dropdown-padding-right: var(--desktop-cyan-spacing);
    --desktop-dropdown-select-dropdown-margin-top: var(--desktop-blue-spacing);

    // ================================= Dropdown Error ================================

    --select-dropdown-error-text-color: var(--error-text-color);
    --select-dropdown-error-border-color: var(--error-border);

    // =============================== Validation Message ==============================

    --validation-message-dropdown-text-color: var(--error-text-color);
    --mobile-validation-message-dropdown-margin-top: var(--mobile-blue-spacing);
    --mobile-validation-message-dropdown-margin-left: var(--mobile-cyan-spacing);
    --tablet-validation-message-dropdown-margin-top: var(--tablet-blue-spacing);
    --tablet-validation-message-dropdown-margin-left: var(--tablet-cyan-spacing);
    --desktop-validation-message-dropdown-margin-top: var(--desktop-blue-spacing);
    --desktop-validation-message-dropdown-margin-left: var(--desktop-cyan-spacing);

    // =================================== Label Text ==================================

    --mobile-select-dropdown-label-margin-left: var(--mobile-blue-spacing);
    --tablet-select-dropdown-label-margin-left: var(--tablet-blue-spacing);
    --desktop-select-dropdown-label-margin-left: var(--desktop-blue-spacing);
    
}