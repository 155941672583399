// Mobile
.link {
    color: var(--link-text-color);
}

.link:hover {
    cursor: pointer;
}

// Tablet
@media (min-width: 768px) { 

}

// Desktop
@media (min-width: 1024px) { 

}