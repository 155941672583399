// Mobile
.disclosures-section {
    margin-bottom: var(--mobile-disclosures-section-margin-bottom);
}

// Tablet
@media (min-width: 768px) { 
    .disclosures-section {
        margin-bottom: var(--tablet-disclosures-section-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .disclosures-section {
        margin-bottom: var(--desktop-disclosures-section-margin-bottom);
    }
}