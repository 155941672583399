// Mobile
.text-legal {
    margin: 0;
    font-family: var(--mobile-legaltext-text-legal-font-family);
    color: var(--mobile-legaltext-text-legal-text-color);
    font-size: var(--mobile-legaltext-text-legal-font-size);
    line-height: var(--mobile-legaltext-text-legal-line-height);
    letter-spacing: var(--mobile-legaltext-text-legal-letter-spacing);
    font-weight: var(--mobile-legaltext-text-legal-font-weight);
}

// Tablet
@media (min-width: 768px) { 
    .text-legal {
        font-size: var(--tablet-legaltext-text-legal-font-size);
        line-height: var(--tablet-legaltext-text-legal-line-height);
        letter-spacing: var(--tablet-legaltext-text-legal-letter-spacing);
        font-weight: var(--tablet-legaltext-text-legal-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
}