// Mobile
.label-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 24px;
    min-width: 24px;
    margin-top: auto;
    margin-bottom: auto;
    font-family: var(--checkbox-label-checkbox-font-family);
    font-size: var(--mobile-checkbox-label-checkbox-font-size);
    line-height: var(--mobile-checkbox-label-checkbox-line-height);
    letter-spacing: var(--mobile-checkbox-label-checkbox-letter-spacing);
    font-weight: var(--mobile-checkbox-label-checkbox-font-weight);
}

.container-checkbox {
    padding-top: var(--mobile-checkbox-label-checkbox-margin-top);
    padding-bottom: var(--mobile-checkbox-label-checkbox-margin-bottom);
}

.label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--checkbox-checkmark-border-color);
    border-radius: 5px;
}
.label-checkbox input:checked ~ .checkmark {
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: var(--checkbox-checkmark-checked-background-color);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.label-checkbox input:checked ~ .checkmark:after {
    display: block;
}
.label-checkbox .checkmark:after {
    left: 7px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid var(--checkbox-checkmark-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.label-text-checkbox {
    margin-left: var(--mobile-checkbox-label-text-checkbox-margin-left);
    display: flex;
    flex-direction: column;
}

.checkbox-validation-message {
    color: var(--checkbox-validation-message-text-color)
}

// Tablet
@media (min-width: 768px) { 
    .label-checkbox {
        font-size: var(--tablet-checkbox-label-checkbox-font-size);
        line-height: var(--tablet-checkbox-label-checkbox-line-height);
        letter-spacing: var(--tablet-checkbox-label-checkbox-letter-spacing);
        font-weight: var(--tablet-checkbox-label-checkbox-font-weight);
    }

    .label-text-checkbox {
        margin-left: var(--tablet-checkbox-label-text-checkbox-margin-left);
    }

    .container-checkbox {
        padding-top: var(--tablet-checkbox-label-checkbox-margin-top);
        padding-bottom: var(--tablet-checkbox-label-checkbox-margin-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .container-checkbox {
        padding-top: var(--desktop-checkbox-label-checkbox-margin-top);
        padding-bottom: var(--desktop-checkbox-label-checkbox-margin-bottom);
    }

    .label-text-checkbox {
        margin-left: var(--desktop-checkbox-label-text-checkbox-margin-left);
    }
}