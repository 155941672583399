// Mobile
.offer-text-container {
    display: flex;
    justify-content: space-between;
    margin-top: var(--mobile-offer-text-container-margin-top);
    margin-bottom: var(--mobile-offer-text-container-margin-bottom);
}
.offer-text-prices-container {
    display: flex;
    gap: var(--mobile-offer-text-prices-container-gap);
}
.offer-text-bold {
    text-align: center;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}
.offer-text-strike {
    text-decoration: line-through;
    text-decoration-color: var(--offer-text-strike-text-color);
    text-align: center;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    color: #918E8E;
}

.offer-text-checkbox {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    height: 16px;
    min-width: 16px;
    background-color: var(--white);
    border: 1.5px solid var(--primary-color);
    border-radius: 100%;
}
.offer-text-checkbox-active {
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: var(--primary-color);
    border-radius: 100%;
}
.offer-text-checkbox-active ~ .offer-text-checkmark {
    display: block;
}
.offer-text-checkmark {
    position: absolute;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--white);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.offer-text-month {
    margin-top: auto;
    margin-bottom: var(--mobile-blue-spacing);
}

.offer-text-real-value-container {
    display: flex;
}

// Tablet
@media (min-width: 768px) { 
    .offer-text-container {
        margin-top: var(--tablet-offer-text-container-margin-top);
        margin-bottom: var(--tablet-offer-text-container-margin-bottom);
    }
    .offer-text-prices-container {
        gap: var(--tablet-offer-text-prices-container-gap);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .offer-text-container {
        margin-top: var(--desktop-offer-text-container-margin-top);
        margin-bottom: var(--desktop-offer-text-container-margin-bottom);
    }
    .offer-text-prices-container {
        gap: var(--desktop-offer-text-prices-container-gap);
    }
}