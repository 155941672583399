// Mobile
.calendar-main {
    border: 2px solid var(--calendar-main-border-color);
    border-radius: 13px;
    background: var(--calendar-main-background-color);
    padding-top: var(--mobile-calendar-main-padding-top);
    padding-bottom: var(--mobile-calendar-main-padding-bottom);
    padding-left: var(--mobile-calendar-main-padding-left);
    padding-right: var(--mobile-calendar-main-padding-right);
}
.calendar-main .react-calendar__navigation {
    display: flex;
    justify-content: space-around;
    padding-bottom: var(--mobile-calendar-navigation-padding-bottom);
    border-bottom: 1px solid var(--calendar-navigation-border-color);
}
.calendar-main .react-calendar__navigation .react-calendar__navigation__label {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: var(--calendar-main-text-color);
    font-family: var(--calendar-navigation-label-font-family);
    font-size: var(--mobile-calendar-navigation-label-font-size);
    line-height: var(--mobile-calendar-navigation-label-line-height);
    letter-spacing: var(--mobile-calendar-navigation-label-letter-spacing);
    font-weight: var(--mobile-calendar-navigation-label-font-weight);
}
.calendar-main .react-calendar__navigation .react-calendar__navigation__arrow {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: var(--calendar-navigation-arrow-text-color);
    font-family: var(--calendar-navigation-arrow-font-family);
    font-size: var(--mobile-calendar-navigation-arrow-font-size);
    line-height: var(--mobile-calendar-navigation-arrow-line-height);
    letter-spacing: var(--mobile-calendar-navigation-arrow-letter-spacing);
    font-weight: var(--mobile-calendar-navigation-arrow-font-weight);
}
.calendar-main .react-calendar__viewContainer {
    margin-top: var(--mobile-calendar-view-margin-top);
    text-align: center;
}
.calendar-main .react-calendar__month-view__weekdays {
    margin-bottom: var(--mobile-calendar-weekdays-margin-bottom);
}
.calendar-main .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-family: var(--calendar-weekdays-weekday-abbr-font-family);
    font-size: var(--mobile-calendar-weekdays-weekday-abbr-font-size);
    line-height: var(--mobile-calendar-weekdays-weekday-abbr-line-height);
    letter-spacing: var(--mobile-calendar-weekdays-weekday-abbr-letter-spacing);
    font-weight: var(--mobile-calendar-weekdays-weekday-abbr-font-weight);
}
.calendar-main .react-calendar__month-view__days {
    gap: 8px;
}
.calendar-main .react-calendar__tile {
    flex: 0 0 calc(14.2857% - 7px) !important;
    aspect-ratio: 1/1;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    // padding-top: var(--mobile-calendar-title-padding-top);
    // padding-bottom: var(--mobile-calendar-title-padding-bottom);
    background: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: var(--calendar-title-text-color);
    font-family: var(--calendar-title-font-family);
    font-size: var(--mobile-calendar-title-font-size);
    line-height: var(--mobile-calendar-title-line-height);
    letter-spacing: var(--mobile-calendar-title-letter-spacing);
    font-weight: var(--mobile-calendar-title-font-weight);
}
.calendar-main .available-date {
    border-radius: 6px;
    border: 1px solid var(--calendar-available-date-border-color);
    color: var(--calendar-available-date-text-color);
}
.calendar-main .active-date {
    border-radius: 6px;
    border: 1px solid var(--calendar-active-date-border-color);
    color: var(--calendar-active-date-text-color);
    background-color: var(--calendar-active-date-background-color);
}

// Tablet
@media (min-width: 768px) { 
    .calendar-container {
        border: 2px solid var(--calendar-main-border-color);
        border-radius: 13px;
        display: flex;
        padding-top: var(--tablet-calendar-main-padding-top);
        padding-bottom: var(--tablet-calendar-main-padding-bottom);
        padding-left: var(--tablet-calendar-main-padding-left);
        padding-right: var(--tablet-calendar-main-padding-right);
    }

    .calendar-main {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        border-radius: 0;
        width: 50%;
        padding-right: var(--tablet-calendar-main-padding-right);
        border-right: 1px solid var(--tablet-calendar-main-border-color);
        margin-right: var(--tablet-calendar-main-margin-right);
    }
    .calendar-main .react-calendar__navigation {
        padding-bottom: var(--tablet-calendar-navigation-padding-bottom);
    }
    .calendar-main .react-calendar__navigation .react-calendar__navigation__label {
        font-size: var(--tablet-calendar-navigation-label-font-size);
        line-height: var(--tablet-calendar-navigation-label-line-height);
        letter-spacing: var(--tablet-calendar-navigation-label-letter-spacing);
        font-weight: var(--tablet-calendar-navigation-label-font-weight);
    }
    .calendar-main .react-calendar__navigation .react-calendar__navigation__arrow {
        font-size: var(--tablet-calendar-navigation-arrow-font-size);
        line-height: var(--tablet-calendar-navigation-arrow-line-height);
        letter-spacing: var(--tablet-calendar-navigation-arrow-letter-spacing);
        font-weight: var(--tablet-calendar-navigation-arrow-font-weight);
    }
    .calendar-main .react-calendar__viewContainer {
        margin-top: var(--tablet-calendar-view-margin-top);
    }
    .calendar-main .react-calendar__month-view__weekdays {
        margin-bottom: var(--tablet-calendar-weekdays-margin-bottom);
    }
    .calendar-main .react-calendar__month-view__weekdays__weekday abbr {
        font-size: var(--tablet-calendar-weekdays-weekday-abbr-font-size);
        line-height: var(--tablet-calendar-weekdays-weekday-abbr-line-height);
        letter-spacing: var(--tablet-calendar-weekdays-weekday-abbr-letter-spacing);
        font-weight: var(--tablet-calendar-weekdays-weekday-abbr-font-weight);
    }
    .calendar-main .react-calendar__tile {
        // padding-top: var(--tablet-calendar-title-padding-top);
        // padding-bottom: var(--tablet-calendar-title-padding-bottom);
        font-size: var(--tablet-calendar-title-font-size);
        line-height: var(--tablet-calendar-title-line-height);
        letter-spacing: var(--tablet-calendar-title-letter-spacing);
        font-weight: var(--tablet-calendar-title-font-weight);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .calendar-container {
        padding-top: var(--desktop-calendar-main-padding-top);
        padding-bottom: var(--desktop-calendar-main-padding-bottom);
        padding-left: var(--desktop-calendar-main-padding-left);
        padding-right: var(--desktop-calendar-main-padding-right);
    }

    .calendar-main {
        padding-right: var(--desktop-calendar-main-padding-right);
        margin-right: var(--desktop-calendar-main-margin-right);
    }
    .calendar-main .react-calendar__navigation {
        padding-bottom: var(--desktop-calendar-navigation-padding-bottom);
    }
    .calendar-main .react-calendar__viewContainer {
        margin-top: var(--desktop-calendar-view-margin-top);
    }
    .calendar-main .react-calendar__month-view__weekdays {
        margin-bottom: var(--desktop-calendar-weekdays-margin-bottom);
    }
    .calendar-main .react-calendar__tile {
        // padding-top: var(--desktop-calendar-title-padding-top);
        // padding-bottom: var(--desktop-calendar-title-padding-bottom);
    }
}