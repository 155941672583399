:root {

    // =================================== calendar__navigation__label ==================================

    // Font Family
    --calendar-navigation-label-font-family: var(--bold-font);

    // Mobile Fonts
    --mobile-calendar-navigation-label-font-size: var(--mobile-input-font-size);
    --mobile-calendar-navigation-label-line-height: var(--mobile-input-line-height);
    --mobile-calendar-navigation-label-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-calendar-navigation-label-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-calendar-navigation-label-font-size: var(--tablet-input-font-size);
    --tablet-calendar-navigation-label-line-height: var(--tablet-input-line-height);
    --tablet-calendar-navigation-label-letter-spacing: var(--tablet-input-letter-spacing);
    --tablet-calendar-navigation-label-font-weight: var(--tablet-input-font-weight);

    // ========================================== calendar-main =========================================

    // Colors
    --calendar-main-border-color: var(--black);
    --calendar-main-text-color: var(--black);
    --calendar-main-background-color: var(--white);
    --tablet-calendar-main-border-color: var(--demo-form-gray);

    // Mobile Padding
    --mobile-calendar-main-padding-top: var(--mobile-green-spacing);
    --mobile-calendar-main-padding-bottom: var(--mobile-green-spacing);
    --mobile-calendar-main-padding-left: var(--mobile-red-spacing);
    --mobile-calendar-main-padding-right: var(--mobile-red-spacing);

    // Tablet Padding
    --tablet-calendar-main-padding-top: var(--tablet-green-spacing);
    --tablet-calendar-main-padding-bottom: var(--tablet-green-spacing);
    --tablet-calendar-main-padding-left: var(--tablet-red-spacing);
    --tablet-calendar-main-padding-right: var(--tablet-red-spacing);

    // Desktop Padding
    --desktop-calendar-main-padding-top: var(--tablet-green-spacing);
    --desktop-calendar-main-padding-bottom: var(--tablet-green-spacing);
    --desktop-calendar-main-padding-left: var(--tablet-red-spacing);
    --desktop-calendar-main-padding-right: var(--tablet-red-spacing);

    --tablet-calendar-main-margin-right: var(--tablet-red-spacing);
    --desktop-calendar-main-margin-right: var(--desktop-red-spacing);

    // ====================================== calendar__navigation ======================================

    // Padding
    --mobile-calendar-navigation-padding-bottom: var(--mobile-cyan-spacing);
    --tablet-calendar-navigation-padding-bottom: var(--tablet-cyan-spacing);
    --desktop-calendar-navigation-padding-bottom: var(--desktop-cyan-spacing);

    // Colors
    --calendar-navigation-border-color: var(--demo-form-gray);

    // ================================== calendar__navigation__arrow ===================================

    // Colors
    --calendar-navigation-arrow-text-color: var(--black);

    // Font Family
    --calendar-navigation-arrow-font-family: var(--bold-font);

    // Mobile Fonts
    --mobile-calendar-navigation-arrow-font-size: var(--mobile-input-font-size);
    --mobile-calendar-navigation-arrow-line-height: var(--mobile-input-line-height);
    --mobile-calendar-navigation-arrow-letter-spacing: var(--mobile-input-letter-spacing);
    --mobile-calendar-navigation-arrow-font-weight: var(--mobile-input-font-weight);

    // Tablet/Desktop Fonts
    --tablet-calendar-navigation-label-font-size: var(--mobile-input-font-size);
    --tablet-calendar-navigation-label-line-height: var(--mobile-input-line-height);
    --tablet-calendar-navigation-label-letter-spacing: var(--mobile-input-letter-spacing);
    --tablet-calendar-navigation-label-font-weight: var(--mobile-input-font-weight);

    // ==================================== calendar__viewContainer ====================================

    --mobile-calendar-view-margin-top: var(--mobile-pink-spacing);
    --tablet-calendar-view-margin-top: var(--tablet-pink-spacing);
    --desktop-calendar-view-margin-top: var(--desktop-pink-spacing);

    // ================================ calendar__month-view__weekdays =================================

    --mobile-calendar-weekdays-margin-bottom: var(--mobile-cyan-spacing);
    --tablet-calendar-weekdays-margin-bottom: var(--tablet-cyan-spacing);
    --desktop-calendar-weekdays-margin-bottom: var(--desktop-cyan-spacing);

    // ========================= calendar__month-view__weekdays__weekday abbr ==========================

    // Font Family
    --calendar-weekdays-weekday-abbr-font-family: var(--legal-font);

    // Mobile Fonts
    --mobile-calendar-weekdays-weekday-abbr-font-size: var(--mobile-legal-font-size);
    --mobile-calendar-weekdays-weekday-abbr-line-height: var(--mobile-legal-line-height);
    --mobile-calendar-weekdays-weekday-abbr-letter-spacing: var(--mobile-legal-letter-spacing);
    --mobile-calendar-weekdays-weekday-abbr-font-weight: var(--mobile-legal-font-weight);

    // Tablet/Desktop Fonts
    --tablet-calendar-weekdays-weekday-abbr-font-size: var(--tablet-legal-font-size);
    --tablet-calendar-weekdays-weekday-abbr-line-height: var(--tablet-legal-line-height);
    --tablet-calendar-weekdays-weekday-abbr-letter-spacing: var(--tablet-legal-letter-spacing);
    --tablet-calendar-weekdays-weekday-abbr-font-weight: var(--tablet-legal-font-weight);

    // ======================================== calendar__tile =========================================

    // Padding
    --mobile-calendar-title-padding-top: var(--mobile-green-spacing);
    --mobile-calendar-title-padding-bottom: var(--mobile-green-spacing);
    --tablet-calendar-title-padding-top: var(--tablet-green-spacing);
    --tablet-calendar-title-padding-bottom: var(--tablet-green-spacing);
    --desktop-calendar-title-padding-top: var(--desktop-green-spacing);
    --desktop-calendar-title-padding-bottom: var(--desktop-green-spacing);

    // Colors
    --calendar-title-text-color: var(--demo-form-gray);

    // Font Family
    --calendar-title-font-family: var(--main-font);

    // Mobile Fonts
    --mobile-calendar-title-font-size: var(--mobile-body-font-size);
    --mobile-calendar-title-line-height: var(--mobile-body-line-height);
    --mobile-calendar-title-letter-spacing: var(--mobile-body-letter-spacing);
    --mobile-calendar-title-font-weight: var(--mobile-body-font-weight);

    // Tablet/Desktop Fonts
    --tablet-calendar-title-font-size: var(--tablet-body-font-size);
    --tablet-calendar-title-line-height: var(--tablet-body-line-height);
    --tablet-calendar-title-letter-spacing: var(--tablet-body-letter-spacing);
    --tablet-calendar-title-font-weight: var(--tablet-body-font-weight);

    // ======================================== available-date =========================================

    --calendar-available-date-border-color: var(--black);
    --calendar-available-date-text-color: var(--black);

    // ========================================== active-date ==========================================

    --calendar-active-date-border-color: var(--primary-color);
    --calendar-active-date-background-color: var(--primary-color);
    --calendar-active-date-text-color: var(--white);

}