/* The switch - the box around the slider */
.switch-label {
    position: relative;
    display: inline-block;
    width: 68px;
    height: 36px;
}

/* Hide default HTML checkbox */
.switch-label input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid var(--switch-slider-border-color);
    background-color: var(--switch-slider-background-color);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 32px;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: -1px;
    bottom: 0px;
    background-color: var(--switch-slider-background-color-before);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .switch-slider {
    background-color: var(--switch-slider-background-color-checked);
    border: 2px solid var(--switch-slider-border-color-checked);
}

input:checked + .switch-slider:before {
    background-color: var(--switch-slider-background-color-switch-checked);
    -webkit-transform: translateX(33px);
    -ms-transform: translateX(33px);
    transform: translateX(33px);
}

// Tablet
@media (min-width: 768px) { 
}

// Desktop
@media (min-width: 1024px) { 
}