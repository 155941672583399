// Mobile
.radio-menu-label {
    color: var(--radio-menu-label-text-color);
    font-family: var(--radio-menu-label-font-family);
    font-size: var(--mobile-radio-menu-label-body-text-font-size);
    line-height: var(--mobile-radio-menu-label-body-text-line-height);
    letter-spacing: var(--mobile-radio-menu-label-body-text-letter-spacing);
    font-weight: var(--mobile-radio-menu-label-body-text-font-weight);
    display: flex;
    gap: var(--mobile-radio-menu-label-gap);
    margin-top: var(--mobile-radio-menu-label-margin-top);
    margin-right: var(--mobile-radio-menu-label-margin-right);
}

.radio-menu-input {
    accent-color: var(--radio-menu-accent-color);
    width: 16px;
    height: 16px;
}

.radio-menu-container {
    display: flex;
    flex-direction: column;
}
.radio-menu-validation-message {
    color: var(--fail-state);
    margin-top: var(--mobile-green-spacing);
}

// Tablet
@media (min-width: 768px) { 
    .radio-menu-label {
        font-size: var(--tablet-radio-menu-label-font-size);
        line-height: var(--tablet-radio-menu-label-line-height);
        letter-spacing: var(--tablet-radio-menu-label-letter-spacing);
        font-weight: var(--tablet-radio-menu-label-font-weight);
        gap: var(--tablet-radio-menu-label-gap);
        margin-top: var(--tablet-radio-menu-label-margin-top);
        margin-right: var(--tablet-radio-menu-label-margin-right);
    }

    .radio-menu-input {
        width: 24px;
        height: 24px;
    }

    .radio-menu-validation-message {
        margin-top: var(--mobile-pink-spacing);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .radio-menu-label {
        gap: var(--desktop-radio-menu-label-gap);
        margin-top: var(--desktop-radio-menu-label-margin-top);
        margin-right: var(--desktop-radio-menu-label-margin-right);
    }
}