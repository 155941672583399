// Mobile
.button-addresssugestions {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    width: 100%;
    display: flex;
    font-family: var(--button-addresssugestions-font-family);
    font-size: var(--mobile-button-addresssugestions-font-size);
    line-height: var(--mobile-button-addresssugestions-line-height);
    letter-spacing: var(--mobile-button-addresssugestions-letter-spacing);
    font-weight: var(--mobile-button-addresssugestions-font-weight);
    gap: var(--mobile-button-addresssugestions-gap);
    padding-left: var(--mobile-button-addresssugestions-padding-left);
    padding-right: var(--mobile-button-addresssugestions-padding-right);
    padding-top: var(--mobile-button-addresssugestions-padding-top);
    padding-bottom: var(--mobile-button-addresssugestions-padding-bottom);
    border-bottom: 1px solid var(--container-addresssugestions-border-color);
}
.button-addresssugestions:hover {
    background-color: var(--button-addresssugestions-background-color-hover);
}
.button-addresssugestions:active {
    background-color: var(--button-addresssugestions-background-color-active);
}
.button-addresssugestions-last {
    border-bottom: 0px;
    border-radius: 0 0 10px 10px;
}

.button-addresssugestions svg {
    margin-top: auto;
    margin-bottom: auto;
}

.suggestion-container-addresssugestions {
    text-align: left;
}

// Tablet
@media (min-width: 768px) { 
    .button-addresssugestions {
        font-size: var(--tablet-button-addresssugestions-font-size);
        line-height: var(--tablet-button-addresssugestions-line-height);
        letter-spacing: var(--tablet-button-addresssugestions-letter-spacing);
        font-weight: var(--tablet-button-addresssugestions-font-weight);
        gap: var(--tablet-button-addresssugestions-gap);
        padding-left: var(--tablet-button-addresssugestions-padding-left);
        padding-right: var(--tablet-button-addresssugestions-padding-right);
        padding-top: var(--tablet-button-addresssugestions-padding-top);
        padding-bottom: var(--tablet-button-addresssugestions-padding-bottom);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .button-addresssugestions {
        gap: var(--dektop-button-addresssugestions-gap);
        padding-left: var(--desktop-button-addresssugestions-padding-left);
        padding-right: var(--desktop-button-addresssugestions-padding-right);
        padding-top: var(--desktop-button-addresssugestions-padding-top);
        padding-bottom: var(--desktop-button-addresssugestions-padding-bottom);
    }
}