// Mobile
.offer-card-card {
    border-radius: 12px;
    border: 1px solid #918E8E;
    padding-left: var(--mobile-offer-card-card-padding-left);
    padding-right: var(--mobile-offer-card-card-padding-right);
}
.offer-card-label {
    position: absolute;
    top: -14px;
    padding-left: var(--mobile-pink-spacing);
    padding-right: var(--mobile-pink-spacing);
    border-radius: 42px;
    padding-top: 2px;
    padding-bottom: 2px;
    left: var(--mobile-pink-spacing);
}

.offer-card-label-purple {
    background-color: #A34EF4; 
    color: var(--white);
}
.offer-card-purple {
    background-color: rgba(163, 78, 244, 0.10); 
}
.offer-card-label-green {
    background-color: #35CE5C; 
    color: var(--white);
}

.offer-card-active {
    border: 1.5px solid var(--black);
}

.offer-card-checkmark {
    width: 6px;
    height: 12px;
    border: solid var(--black);
    border-width: 0 2.5px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 4px;
}

.offer-card-label-margin {
    margin-top: calc(var(--mobile-cyan-spacing) + var(--mobile-blue-spacing) - var(--mobile-green-spacing));
}

.offer-card-description {
    position: relative; 
    display: flex;
    gap: var(--mobile-pink-spacing); 
    margin-left: calc(var(--mobile-cyan-spacing) + 6px);
}

.offer-card-button-margins {
    margin-bottom: 16px;
}

// Tablet
@media (min-width: 768px) { 
    .offer-card-card {
        padding-left: var(--tablet-offer-card-card-padding-left);
        padding-right: var(--tablet-offer-card-card-padding-right);
    }

    .offer-card-label {
        left: var(--tablet-cyan-spacing);
    }

    .offer-card-label-margin {
        margin-top: calc(var(--tablet-red-spacing) - var(--tablet-pink-spacing));
    }

    .offer-card-description {
        margin-left: var(--tablet-sea-green-spacing);
    }

    .offer-card-card {
        padding-left: var(--tablet-red-spacing);
        padding-right: var(--tablet-red-spacing);
    }

    .offer-card-description-container {
        display: flex;
        justify-content: space-around;
        gap: var(--tablet-cyan-spacing);
    }

    .offer-card-button-margins {
        width: 33% !important;
    }
}

// Desktop
@media (min-width: 1024px) { 
    .offer-card-card {
        padding-left: var(--desktop-offer-card-card-padding-left);
        padding-right: var(--desktop-offer-card-card-padding-right);
    }

}