// Mobile
.address-field-state-zip-container {
    display: flex;
    gap: var(--mobile-address-field-state-zip-container-gap);
}
.address-field-state-zip-container label {
    width: calc(50% - calc(var(--mobile-address-field-state-zip-container-gap) / 2));
}
.address-field-state-dropdown {
    width: 100%;
}
.address-field-sublabel-text {
    margin-bottom: var(--mobile-textfield-sublabel-textfield-margin-bottom);
}
.address-field-poweredBy-text {
    margin-bottom: var(--mobile-textfield-poweredBy-textfield-margin-bottom);
    font-style: italic;
    font-size: var(--mobile-small-font-size);
    margin-left: var(--mobile-blue-spacing);
}


// Tablet
@media (min-width: 768px) { 
    .address-field-state-zip-container {
        gap: var(--tablet-address-field-state-zip-container-gap);
    }
    .address-field-state-zip-container label {
        width: calc(50% - calc(var(--tablet-address-field-state-zip-container-gap) / 2));
    }
    .address-field-sublabel-text {
        margin-bottom: var(--tablet-textfield-sublabel-textfield-margin-bottom);
    }
    .address-field-poweredBy-text {
        margin-top: -12px;
        font-size: var(--tablet-small-font-size);
    }
}

// Desktop
@media (min-width: 1024px) { 
    .address-field-state-zip-container {
        gap: var(--desktop-address-field-state-zip-container-gap);
    }
    .address-field-state-zip-container label {
        width: calc(50% - calc(var(--desktop-address-field-state-zip-container-gap) / 2));
    }
    .address-field-sublabel-text {
        margin-bottom: var(--desktop-textfield-sublabel-textfield-margin-bottom);
    }
    .address-field-poweredBy-text {
        margin-top: -12px;
        font-size: var(--tablet-small-font-size);
    }
}